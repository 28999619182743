import { format, addDays } from "date-fns";

export const excelDateFormat = (dateString) => {
  if (!dateString) return;
  
  // Excel base date
  const baseDate = new Date(1900, 0, 1);

  const daysToAdd = Number.parseInt(dateString, 10);

  const resultDate = addDays(baseDate, daysToAdd);

  return format(resultDate, 'MM/yy');
};
