import localforage from "localforage";

export default async function getLocalForage(item) {
  const data = await localforage.getItem(item);

  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
}
