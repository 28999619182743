import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import infoWithGmail from "../../helpers/infoWithGmail";

function ManagerInputs({ handleOnChange, value }) {
  return (
    <Select
      value={value}
      onChange={handleOnChange}
      className="clients__select-input"
      displayEmpty
    >
      <MenuItem value="">Todos los comerciales</MenuItem>
      <MenuItem value="alejandromicroshop@gmail.com">
        {infoWithGmail("alejandromicroshop@gmail.com").name}
      </MenuItem>
      <MenuItem value="luisgalan67@gmail.com">
        {infoWithGmail("luisgalan67@gmail.com").name}
      </MenuItem>
      <MenuItem value="diegomicroshop@gmail.com">
        {infoWithGmail("diegomicroshop@gmail.com").name}
      </MenuItem>
      <MenuItem value="javiermicroshop@gmail.com">
        {infoWithGmail("javiermicroshop@gmail.com").name}
      </MenuItem>
      <MenuItem value="juancarlosmicroshop@gmail.com">
        {infoWithGmail("juancarlosmicroshop@gmail.com").name}
      </MenuItem>
      <MenuItem value="maitemicroshop@gmail.com">
        {infoWithGmail("maitemicroshop@gmail.com").name}
      </MenuItem>
      <MenuItem value="elenaolivaresmicroshop@gmail.com">
        {infoWithGmail("elenaolivaresmicroshop@gmail.com").name}
      </MenuItem>
      <MenuItem value="stevemarcelomicroshop@gmail.com">
        {infoWithGmail("stevemarcelomicroshop@gmail.com").name}
      </MenuItem>
    </Select>
  );
}

export default ManagerInputs;
