const themeDark = {
  palette: {
    primary: {
      light: "#6ce8ff",
      main: "#1ba5cf",
      dark: "#0086b3",
      contrastText: "#fff",
    },
    text: {
      primary: "#1ba5cf",
    },
    background: { default: "#0c4b5c", paper: "#0c4b5c" },
  },
  table: {
    border: "#0c4152",
  },
  overrides: {
    MuiTableRow: {
      root: {
        "&.MuiTableRow-hover:hover": {
          backgroundColor: "#0d4757",
        },
      },
    },
    MuiIconButton: {
      root: {
        color: "#1ba5cf",
      },
    },
  },
};

export default themeDark;
