import React from "react";
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

export default function GroupConnectivity({ values, handleChange }) {
  if (values["category"] === "CONNECTIVITY") {
    return (
      <>
        <InputLabel id="type">Tipo *</InputLabel>
        <Select
          labelId="type"
          name="typeConnectivity"
          onChange={handleChange}
          value={values.typeConnectivity}
        >
          <MenuItem value="NEGOCIOS">Fusión Negocios</MenuItem>
          <MenuItem value="EMPRESA">Fusión Empresa</MenuItem>
        </Select>
        <Typography gutterBottom paragraph>
          <TextField
            label="Descripción *"
            name="specsDescriptionConnectivity"
            fullWidth
            onChange={handleChange}
            value={values.specsDescriptionConnectivity}
          />
        </Typography>
      </>
    );
  } else {
    return null;
  }
}
