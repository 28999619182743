import React from "react";
import { Button } from "@material-ui/core";
import { Map as MapIcon } from "@material-ui/icons";

function OpenMapsButton({ data }) {
  const handleOnClick = () => {
    window.open(
      `https://google.es/maps/search/${data["CLI_NOMBRE"]}+${data["CLI_DIRECCION"]}`,
      "_blank"
    );
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<MapIcon />}
      onClick={handleOnClick}
    >
      Abrir en Maps
    </Button>
  );
}

export default OpenMapsButton;
