import React from "react";
import { Typography, TextField } from "@material-ui/core";

export default function GroupAccessories({ values, handleChange }) {
  if (values["category"] === "ACCESSORIES") {
    return (
      <>
        <Typography gutterBottom paragraph>
          <TextField
            label="Otros (1) *"
            name="specsOthers1Accessories"
            fullWidth
            onChange={handleChange}
            value={values.specsOthers1Accessories}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Otros (2) *"
            name="specsOthers2Accessories"
            fullWidth
            onChange={handleChange}
            value={values.specsOthers2Accessories}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Otros (3) *"
            name="specsOthers3Accessories"
            fullWidth
            onChange={handleChange}
            value={values.specsOthers3Accessories}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="URL Relativa de Imagen *"
            name="imageUrlPhones"
            fullWidth
            onChange={handleChange}
            value={values.imageUrlPhones}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="URL Más Info *"
            name="moreInfoUrlPhones"
            fullWidth
            onChange={handleChange}
            value={values.moreInfoUrlPhones}
          />
        </Typography>
      </>
    );
  } else {
    return null;
  }
}
