import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  TextField,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import { FirebaseContext } from "../../Firebase";
import { Formik } from "formik";
import validationSchema from "./validationSchema";
// Categories Groups
import GroupPhones from "./FormGroups/GroupPhones";
import GroupTablets from "./FormGroups/GroupTablets";
import GroupRates from "./FormGroups/GroupRates";
import GroupLaptops from "./FormGroups/GroupLaptops";
import GroupAccessories from "./FormGroups/GroupAccessories";
import GroupServices from "./FormGroups/GroupServices";
import GroupConnectivity from "./FormGroups/GroupConnectivity";
import GroupWiringPoints from "./FormGroups/GroupWiringPoints";
import GroupSwitch from "./FormGroups/GroupSwitch";
import GroupLandPhones from "./FormGroups/GroupLandPhones";
import GroupExtras from "./FormGroups/GroupExtras";

export default function DialogEditProduct({
  open,
  data,
  handleCloseFromProducts,
}) {
  const firebase = useContext(FirebaseContext);

  // Prepare initialValues for formik
  const initialValues = { ...data };
  delete initialValues.id;
  // Id for update changes in Firestore
  const docId = data?.id;

  // Alert for errors on the form
  const [alert, setAlert] = useState({
    open: false,
    message: null,
    severity: null,
  });

  function alertClose() {
    setAlert({ ...alert, open: false });
  }

  function onSubmit(values, { setSubmitting }) {
    try {
      validationSchema.validateSync(values);

      for (const property in values) {
        if (typeof values[property] === "string") {
          values[property] = values[property].toUpperCase();
        }
      }

      firebase.updateProduct({ docId, data: values }).then(() => {
        // ? alert will remain after unmount this component?
        setAlert({
          open: true,
          message: "Producto añadido correctamente",
          severy: "success",
        });

        handleCloseFromProducts();
      });
    } catch (error) {
      setAlert({
        open: true,
        message: error?.message
          ? error.message
          : "¡Ocurrió un error, contacta con el Administrador!",
        severity: "error",
      });

      window.setTimeout(() => setSubmitting(false), 1000);
    }
  }

  if (data === null) {
    return null;
  } else {
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={alert.open}
          autoHideDuration={4000}
          onClose={alertClose}
        >
          <Alert onClose={alertClose} severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
        <Dialog
          onClose={handleCloseFromProducts}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleCloseFromProducts}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" style={{ padding: 8 }}>
                Editar producto
              </Typography>
              <IconButton aria-label="close" onClick={handleCloseFromProducts}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, isSubmitting, handleChange, handleSubmit }) => (
              <>
                <DialogContent dividers>
                  <FormControl
                    fullWidth
                    className="products__form-control-margin"
                  >
                    <InputLabel id="category">Categoría *</InputLabel>
                    <Select
                      labelId="category"
                      name="category"
                      onChange={handleChange}
                      value={values["category"]}
                    >
                      <MenuItem value="PHONES">Terminales</MenuItem>
                      <MenuItem value="RATES">Tarifas</MenuItem>
                      <MenuItem value="TABLETS">Tablets</MenuItem>
                      <MenuItem value="LAPTOPS">Portátiles</MenuItem>
                      <MenuItem value="ACCESSORIES">Accesorios</MenuItem>
                      <MenuItem value="SERVICES">Servicios</MenuItem>
                      <MenuItem value="CONNECTIVITY">Conectividad</MenuItem>
                      <MenuItem value="WIRINGPOINTS">
                        Puntos de cableado
                      </MenuItem>
                      <MenuItem value="SWITCH">Switch</MenuItem>
                      <MenuItem value="LANDPHONES">Puestos fijos</MenuItem>
                      <MenuItem value="EXTRAS">Extras</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography gutterBottom paragraph>
                    <TextField
                      label="Título *"
                      name="title"
                      fullWidth
                      onChange={handleChange}
                      value={values["title"]}
                    />
                  </Typography>
                  <Typography gutterBottom paragraph>
                    <TextField
                      label="Precio *"
                      type="number"
                      name="price"
                      fullWidth
                      onChange={handleChange}
                      value={values["price"]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                      }}
                    />
                  </Typography>
                  <GroupPhones values={values} handleChange={handleChange} />
                  <GroupRates values={values} handleChange={handleChange} />
                  <GroupTablets values={values} handleChange={handleChange} />
                  <GroupLaptops values={values} handleChange={handleChange} />
                  <GroupAccessories
                    values={values}
                    handleChange={handleChange}
                  />
                  <GroupServices values={values} handleChange={handleChange} />
                  <GroupConnectivity
                    values={values}
                    handleChange={handleChange}
                  />
                  <GroupWiringPoints
                    values={values}
                    handleChange={handleChange}
                  />
                  <GroupSwitch values={values} handleChange={handleChange} />
                  <GroupLandPhones
                    values={values}
                    handleChange={handleChange}
                  />
                  <GroupExtras values={values} handleChange={handleChange} />
                </DialogContent>

                <DialogActions>
                  <Button
                    autoFocus
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    color="primary"
                  >
                    Confirmar
                  </Button>
                </DialogActions>
              </>
            )}
          </Formik>
        </Dialog>
      </>
    );
  }
}
