import React from "react";
import { CircularProgress } from "@material-ui/core";
import "./style.css";

function Loading() {
  return (
    <div className="loading__content">
      <CircularProgress />
    </div>
  );
}

export default Loading;
