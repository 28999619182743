import numberToCurrency from "../../helpers/numberToCurrency";

export default function columns(windowSize) {
  const columnsDesktop = [
    { id: "title", label: "Producto", minWidth: 170, align: "center" },
    {
      id: "price",
      label: "Precio",
      minWidth: 100,
      align: "center",
      format: (value) => numberToCurrency(value.price),
    },
  ];

  const columnsMobile = [
    { id: "title", label: "Producto", align: "center" },
    {
      id: "price",
      label: "Precio",
      align: "center",
      format: (value) => numberToCurrency(value.price),
    },
  ];

  if (windowSize === "desktop") {
    return columnsDesktop;
  } else {
    return columnsMobile;
  }
}
