import React from "react";
import { Button } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

function CloseButton() {
  const handleOnClick = () => {
    window.close();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<CloseIcon />}
      onClick={handleOnClick}
    >
      Cerrar
    </Button>
  );
}

export default CloseButton;
