import React from "react";
import { Typography, TextField } from "@material-ui/core";

export default function GroupSwitch({ values, handleChange }) {
  if (values["category"] === "SWITCH") {
    return (
      <>
        <Typography gutterBottom paragraph>
          <TextField
            label="Descripción *"
            name="specsDescriptionSwitch"
            fullWidth
            onChange={handleChange}
            value={values.specsDescriptionSwitch}
          />
        </Typography>
      </>
    );
  } else {
    return null;
  }
}
