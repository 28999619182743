import permissions from "../../helpers/permissions";

function columns(windowSize, email) {
  let columnsDesktop = null;

  if (permissions("manager", email)) {
    columnsDesktop = [
      {
        id: "comercial",
        label: "Comercial",
        align: "center",
      },
      { id: "code", label: "Código", align: "center" },
      { id: "vat", label: "NIF", align: "center" },
      { id: "name", label: "Empresa", align: "center" },
      {
        id: "indicadores",
        label: "Indicadores",
        align: "center",
      },
      {
        id: "lastVisit",
        label: "Última visita",
        align: "center",
      },
    ];
  } else {
    columnsDesktop = [
      { id: "code", label: "Código", align: "center" },
      { id: "vat", label: "NIF", align: "center" },
      { id: "name", label: "Empresa", align: "center" },
      {
        id: "indicadores",
        label: "Indicadores",
        align: "center",
      },
      {
        id: "lastVisit",
        label: "Última visita",
        align: "center",
      },
    ];
  }

  let columnsMobile = null;

  if (permissions("manager", email)) {
    columnsMobile = [
      {
        id: "comercial",
        label: "Comercial",
        align: "center",
      },
      { id: "name", label: "Empresa", align: "center" },
    ];
  } else {
    columnsMobile = [
      { id: "name", label: "Empresa", align: "center" },
      {
        id: "indicadores",
        label: "Indicadores",
        align: "center",
      },
    ];
  }

  if (windowSize === "desktop") {
    return columnsDesktop;
  } else {
    return columnsMobile;
  }
}

export default columns;
