import React from "react";
import { Select, MenuItem } from "@material-ui/core";

export default function CodesFilter({ handleOnChange, value }) {
  return (
    <Select
      value={value}
      onChange={handleOnChange}
      className="clients__select-input"
      displayEmpty
    >
      <MenuItem value="">Todos los códigos</MenuItem>
      <MenuItem value="FV1WFGVT">GVT</MenuItem>
      <MenuItem value="FV1WFK2Z">K2Z</MenuItem>
      <MenuItem value="FV1WFN7D">N7D</MenuItem>
      <MenuItem value="FV1WFNFG">NFG</MenuItem>
      <MenuItem value="FV1WFXCU">XCU</MenuItem>
      <MenuItem value="FV1WFZF7">ZF7</MenuItem>
      <MenuItem value="FV1WFGEVENTAS">Geventas</MenuItem>
    </Select>
  );
}
