import React from "react";
import { Route, Redirect } from "react-router-dom";
// Components
import Loading from "../Loading";
import Forbidden from "../Forbidden";
// Helpers
import permissions from "../../helpers/permissions";

export default function ProtectedRoute({
  path,
  loading,
  userData,
  role,
  children,
}) {
  return (
    <Route
      path={path}
      render={() => {
        if (loading) {
          return <Loading />;
        } else if (userData) {
          if (permissions(role, userData.email)) {
            return children;
          } else {
            return <Forbidden />;
          }
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
}
