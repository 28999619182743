import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  TextField,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import { FirebaseContext } from "../Firebase";
import { AuthContext } from "../Auth";
import { Formik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  "INTERLOCUTOR EMAIL": yup.lazy((value) =>
    !value
      ? yup.string().notRequired()
      : yup.string().email("¡Email de Interlocutor no válido!")
  ),
  "INTERLOCUTOR MOVIL": yup.lazy((value) =>
    !value
      ? yup.string().notRequired()
      : yup
          .string()
          .min(9, "¡Móvil de Interlocutor no válido!")
          .max(9, "¡Móvil de Interlocutor no válido!")
  ),
  "INTERLOCUTOR FIJO": yup.lazy((value) =>
    !value
      ? yup.string().notRequired()
      : yup
          .string()
          .min(9, "¡Fijo de Interlocutor no válido!")
          .max(9, "¡Fijo de Interlocutor no válido!")
  ),
  Interlocutor: yup
    .string()
    .min(2, "¡Nombre de Interlocutor demasiado corto!")
    .max(35, "¡Nombre de Interlocutor demasiado largo!")
    .required("¡Completa el nombre de la Interlocutor!"),
  CLI_POBLACION: yup
    .string()
    .min(2, "Población demasiado corta!")
    .max(35, "Población demasiado larga!")
    .required("¡Completa la Población!"),
  CLI_DIRECCION: yup
    .string()
    .min(8, "¡Dirección demasiado corta!")
    .max(50, "¡Dirección demasiado larga!")
    .required("¡Completa la Dirección!"),
  CLI_ID_FISCAL: yup
    .string()
    .min(9, "¡NIF no válido!")
    .max(9, "¡NIF no válido!")
    .required("¡Completa el NIF!"),
  CLI_NOMBRE: yup
    .string()
    .min(2, "¡Nombre de Empresa demasiado corto!")
    .max(35, "¡Nombre de Empresa demasiado largo!")
    .required("¡Completa el nombre de la Empresa!"),
});

function DialogAddCustomClient({ open, handleCloseFromClients }) {
  const firebase = useContext(FirebaseContext);
  const auth = useContext(AuthContext);

  const [alert, setAlert] = useState({
    open: false,
    message: null,
    severity: null,
  });

  const alertClose = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert.open}
        autoHideDuration={4000}
        onClose={alertClose}
      >
        <Alert onClose={alertClose} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Dialog
        onClose={handleCloseFromClients}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseFromClients}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" style={{ padding: 8 }}>
              Añadir cliente
            </Typography>
            <IconButton aria-label="close" onClick={handleCloseFromClients}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <Formik
          initialValues={{
            CUSTOM_CLIENT: true,
            DIS_CODIGO_FV: "FV1WFGEVENTAS",
            CLI_NOMBRE: "",
            CLI_ID_FISCAL: "",
            CLI_DIRECCION: "",
            CLI_POBLACION: "",
            Interlocutor: "",
            "INTERLOCUTOR FIJO": "",
            "INTERLOCUTOR MOVIL": "",
            "INTERLOCUTOR EMAIL": "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            try {
              validationSchema.validateSync(values);

              for (const property in values) {
                if (typeof values[property] === "string") {
                  values[property] = values[property].toUpperCase();
                }
              }

              firebase.setCustomClient(auth.email, values).then(() => {
                // ? alert will remain after unmount this component?
                setAlert({
                  open: true,
                  message: "Cliente añadido correctamente",
                  severy: "success",
                });

                handleCloseFromClients();
              });
            } catch (error) {
              // Here will catch error with validation and also with Firebase. If "Contact with Admin", is because it's needed to add a new email in "custom_clients" collection
              setAlert({
                open: true,
                message: error?.message
                  ? error.message
                  : "¡Ocurrió un error, contacta con el Administrador!",
                severity: "error",
              });

              window.setTimeout(() => setSubmitting(false), 1000);
            }
          }}
        >
          {({ values, isSubmitting, handleChange, handleSubmit }) => (
            <>
              <DialogContent dividers>
                <Typography gutterBottom>
                  <TextField
                    label="Empresa *"
                    name="CLI_NOMBRE"
                    fullWidth
                    onChange={handleChange}
                    value={values["CLI_NOMBRE"]}
                  />
                </Typography>
                <Typography gutterBottom>
                  <TextField
                    label="NIF *"
                    name="CLI_ID_FISCAL"
                    fullWidth
                    onChange={handleChange}
                    value={values["CLI_ID_FISCAL"]}
                  />
                </Typography>
                <Typography gutterBottom>
                  <TextField
                    label="Dirección *"
                    name="CLI_DIRECCION"
                    fullWidth
                    onChange={handleChange}
                    value={values["CLI_DIRECCION"]}
                  />
                </Typography>
                <Typography gutterBottom>
                  <TextField
                    label="Población *"
                    name="CLI_POBLACION"
                    fullWidth
                    onChange={handleChange}
                    value={values["CLI_POBLACION"]}
                  />
                </Typography>
                <Typography gutterBottom>
                  <TextField
                    label="Nombre del Interlocutor *"
                    name="Interlocutor"
                    fullWidth
                    onChange={handleChange}
                    value={values["Interlocutor"]}
                  />
                </Typography>
                <Typography gutterBottom>
                  <TextField
                    label="Teléfono Fijo"
                    name="INTERLOCUTOR FIJO"
                    fullWidth
                    onChange={handleChange}
                    value={values["INTERLOCUTOR FIJO"]}
                  />
                </Typography>
                <Typography gutterBottom>
                  <TextField
                    label="Teléfono Móvil"
                    name="INTERLOCUTOR MOVIL"
                    fullWidth
                    onChange={handleChange}
                    value={values["INTERLOCUTOR MOVIL"]}
                  />
                </Typography>
                <Typography gutterBottom>
                  <TextField
                    label="Email"
                    name="INTERLOCUTOR EMAIL"
                    fullWidth
                    onChange={handleChange}
                    value={values["INTERLOCUTOR EMAIL"]}
                  />
                </Typography>
              </DialogContent>

              <DialogActions>
                <Button
                  autoFocus
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  color="primary"
                >
                  Confirmar
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default DialogAddCustomClient;
