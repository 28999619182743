import React from "react";
import { Typography, TextField, InputAdornment } from "@material-ui/core";

export default function GroupLaptops({ values, handleChange }) {
  if (values["category"] === "LAPTOPS") {
    return (
      <>
        <Typography gutterBottom paragraph>
          <TextField
            label="Pantalla *"
            name="specsDisplayLaptops"
            fullWidth
            onChange={handleChange}
            value={values.specsDisplayLaptops}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Procesador *"
            name="specsCpuLaptops"
            fullWidth
            onChange={handleChange}
            value={values.specsCpuLaptops}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Almacenamiento *"
            type="number"
            name="specsInternalStorageLaptops"
            fullWidth
            onChange={handleChange}
            value={values.specsInternalStorageLaptops}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">GB</InputAdornment>
              ),
            }}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="RAM *"
            type="number"
            name="specsRamStorageLaptops"
            fullWidth
            onChange={handleChange}
            value={values.specsRamStorageLaptops}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">GB</InputAdornment>
              ),
            }}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="URL Relativa de Imagen *"
            name="imageUrlLaptops"
            fullWidth
            onChange={handleChange}
            value={values.imageUrlLaptops}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="URL Más Info *"
            name="moreInfoUrlLaptops"
            fullWidth
            onChange={handleChange}
            value={values.moreInfoUrlLaptops}
          />
        </Typography>
      </>
    );
  } else {
    return null;
  }
}
