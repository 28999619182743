import React from "react";
import { Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

function NewVisitButton({ handleOnClick }) {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={handleOnClick}
    >
      Agendar Nueva Visita
    </Button>
  );
}

export default NewVisitButton;
