import React from "react";
import firebase from "firebase/compat/app";
import config from "./config";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import permissions from "../../helpers/permissions";

// Firebase Class
class FirebaseClass {
  constructor() {
    firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.db = firebase.firestore();
  }

  formatDate = (firestoreDate) => firestoreDate.toDate(firestoreDate);

  getUpdateStorageDate = () =>
    this.db.collection("clear_storage").doc("0").get();

  getClients = (userEmail) => {
    if (permissions("manager", userEmail)) {
      return this.db.collection("clients");
    } else {
      return this.db.collection("clients").doc(userEmail);
    }
  };

  getCustomClients = (userEmail) => {
    if (permissions("manager", userEmail)) {
      return this.db.collection("custom_clients");
    } else {
      return this.db.collection("custom_clients").doc(userEmail);
    }
  };

  getProducts = () => this.db.collection("products");

  getVisits = ({ userEmail, minDate, maxDate }) => {
    if (permissions("manager", userEmail)) {
      return this.db
        .collection("diaries")
        .where("date", ">=", minDate)
        .where("date", "<=", maxDate);
    } else {
      return this.db
        .collection("diaries")
        .where("user", "==", userEmail)
        .where("date", ">=", minDate)
        .where("date", "<=", maxDate);
    }
  };

  getBudgets = ({ userEmail, minDate, maxDate }) => {
    if (permissions("manager", userEmail)) {
      return this.db
        .collection("budgets")
        .where("date", ">=", minDate)
        .where("date", "<=", maxDate);
    } else {
      return this.db
        .collection("budgets")
        .where("user", "==", userEmail)
        .where("date", ">=", minDate)
        .where("date", "<=", maxDate);
    }
  };

  getEmail = (vat) =>
    this.db
      .collection("emails")
      .doc(vat)
      .get()
      .then((doc) => {
        return doc.data();
      });

  setEmail = (vat, data) => this.db.collection("emails").doc(vat).set(data);

  setCustomClient = (userEmail, data) =>
    this.db
      .collection("custom_clients")
      .doc(userEmail)
      .update({
        clients: firebase.firestore.FieldValue.arrayUnion(data),
      });

  addProduct = (data) => this.db.collection("products").add(data);

  updateProduct = ({ docId, data }) =>
    this.db.collection("products").doc(docId).update(data);

  updateVisit = (id, data) =>
    this.db.collection("diaries").doc(id).update(data);

  addVisit = (data) => this.db.collection("diaries").add(data);

  getIdToken = () => firebase.auth().currentUser.getIdToken();

  getAllDocs = (type, collection, filter, value) => {
    const diaryRef = this.db.collection(collection);

    let query = diaryRef;

    if (filter) {
      query = diaryRef.where(filter, "==", value);
    }

    if (type === "listen") {
      return new Promise((resolve) => {
        query.onSnapshot((querySnapshot) => {
          const docs = [];

          querySnapshot.forEach((doc) => {
            docs.push(doc.data());
          });

          resolve(docs);
        });
      });
    } else if (type === "once") {
      return new Promise((resolve) => {
        query.get().then((querySnapshot) => {
          const docs = [];

          querySnapshot.forEach((doc) => {
            docs.push(doc.data());
          });

          resolve(docs);
        });
      });
    }
  };

  getEmailFromClient = (vat) => this.db.collection("emails").doc(vat).get();

  signIn = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    const result = await this.auth.signInWithPopup(provider);
    const token = result.credential.accessToken;
    const user = result.user;
    return { token, user };
  };

  signOut = () => {
    return this.auth.signOut();
  };
}

// Firebase Context
const FirebaseContext = React.createContext(null);

export { FirebaseContext, FirebaseClass };
