/**
 * ? INDICADORES (ETIQUETAS)
 */
export default function createIndicadores(client) {
  const keys = Object.keys(client);
  const values = [];
  keys.forEach((key, index) => {
    if (
      client[key] &&
      (client[key] === "SI" || client[key] === "S" || client[key] > 0) &&
      key !== "CLI_DIRECCION" &&
      key !== "Campaña NBO NBA" &&
      !key.startsWith("INTERLOCUTOR")
    ) {
      let keyFormatted = key
        .replace("CLI_", "")
        .replace("_", " ")
        .replace("(S/N)", "")
        .replace("TESA::", "")
        .replace("SCRIPT::", "")
        .replace("MICRO::", "")
        .replace("ORDER::", "")
        .replace(/PORTAS::\S*/, "")
        .replace("CAPTAR ", "")
        .replace("EQUIPAMIENTO ", "")
        .replace("FONDOS KIT ", "Kit ")
        .replace("Fusión Empresas", "FD")
        .replace("Segundas sedes", "2ª Sedes");
      if (keyFormatted === "Cliente promo F. Digital 30% 2 años fin o prox fin NO ofrecer Promo") {
        keyFormatted = "No Ofrecer Promo";
      } else if (keyFormatted === "Clientes para Reblindar (informativa)") {
        keyFormatted = "Reblindar";
      }

      if (keyFormatted !== "") {
        values.push(
          <div class="indicadorRounded" key={`${key}${index}`}>
            {keyFormatted}
          </div>
        );
      }
    }
  });
  return values;
}
