import React from "react";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "./style.css";
import logo from "../../logo.png";

function NoMatch() {
  let history = useHistory();

  const goHome = () => {
    history.push("/");
  };

  return (
    <div className="container">
      <img src={logo} width="250px;" alt="Logotipo de Geventas" />
      <Alert className="alert-block" severity="warning">
        La página solicitada no existe.
      </Alert>
      <Button variant="contained" color="primary" onClick={goHome}>
        Volver al inicio
      </Button>
    </div>
  );
}

export default NoMatch;
