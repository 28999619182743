import React from "react";
import { Switch, Button } from "@material-ui/core";
import permissions from "../../helpers/permissions";
import { Mail } from "@material-ui/icons";

function columns(windowSize, email, handleVisited, sendEmail) {
  let columnsDesktop = null;

  if (permissions("manager", email)) {
    columnsDesktop = [
      {
        id: "comercial",
        label: "Comercial",
        align: "center",
      },
      { id: "name", label: "Empresa", minWidth: 170, align: "center" },
      { id: "vat", label: "NIF", minWidth: 100, align: "center" },
      {
        id: "hour",
        label: "Hora",
        minWidth: 170,
        align: "center",
      },
      {
        id: "commentary",
        label: "Comentarios",
        minWidth: 170,
        align: "center",
      },
      {
        id: "visited",
        label: "Visitado",
        minWidth: 100,
        align: "center",
        format: ({ visited, id }) => (
          <Switch
            checked={visited}
            onChange={() => {
              handleVisited(visited, id);
            }}
            color="primary"
          />
        ),
      },
      {
        id: "sendEmail",
        label: "Email",
        minWidth: 50,
        align: "center",
        format: ({ id }) => (
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              sendEmail(id);
            }}
          >
            <Mail />
          </Button>
        ),
      },
    ];
  } else {
    columnsDesktop = [
      { id: "name", label: "Empresa", minWidth: 170, align: "center" },
      { id: "vat", label: "NIF", minWidth: 100, align: "center" },
      {
        id: "hour",
        label: "Hora",
        minWidth: 170,
        align: "center",
      },
      {
        id: "address",
        label: "Dirección",
        minWidth: 170,
        align: "center",
      },
      {
        id: "commentary",
        label: "Comentarios",
        minWidth: 170,
        align: "center",
      },
      {
        id: "visited",
        label: "Visitado",
        minWidth: 100,
        align: "center",
        format: ({ visited, id }) => (
          <Switch
            checked={visited}
            onChange={() => {
              handleVisited(visited, id);
            }}
            color="primary"
          />
        ),
      },
      {
        id: "sendEmail",
        label: "Email",
        minWidth: 50,
        align: "center",
        format: ({ id }) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              sendEmail(id);
            }}
          >
            <Mail />
          </Button>
        ),
      },
    ];
  }

  let columnsMobile = null;

  if (permissions("manager", email)) {
    columnsMobile = [
      {
        id: "comercial",
        label: "Comercial",
        align: "center",
      },
      { id: "name", label: "Empresa", align: "center" },
      {
        id: "hour",
        label: "Hora",
        align: "center",
      },
    ];
  } else {
    columnsMobile = [
      { id: "name", label: "Empresa", align: "center" },
      {
        id: "hour",
        label: "Hora",
        align: "center",
      },
      {
        id: "visited",
        label: "Visitado",
        align: "center",
        format: ({ visited, id }) => (
          <Switch
            checked={visited}
            onChange={() => {
              handleVisited(visited, id);
            }}
            color="primary"
          />
        ),
      },
    ];
  }

  if (windowSize === "desktop") {
    return columnsDesktop;
  } else {
    return columnsMobile;
  }
}

export default columns;
