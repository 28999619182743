import React, { useState } from "react";
import { Drawer } from "@material-ui/core";
import windowSize from "../../helpers/windowsize";
import "./style.css";
import logo from "../../logo.png";
// Import Buttons
import CloseButton from "./Buttons/CloseButton";
import OptionsButton from "./Buttons/OptionsButton";
import SearchGoogleButton from "./Buttons/SearchGoogleButton";
import OpenMapsButton from "./Buttons/OpenMapsButton";
import PrintButton from "./Buttons/PrintButton";
import NewVisitButton from "./Buttons/NewVisitButton";
import DialogNewVisit from "../Diary/DialogNewVisit";

function Header({ data }) {
  const [dialogNewVisitOpen, setDialogNewVisitOpen] = useState(false);

  const handleCloseDialogNewVisit = () => {
    setDialogNewVisitOpen(false);
  };

  const handleOnClickDialogNewVisit = () => {
    setDialogNewVisitOpen(true);
  };

  // Resize listener
  const { width } = windowSize();

  // Drawer toggler (mobile)
  const [drawer, setDrawer] = React.useState(false);

  const toggleDrawer = (toggle) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(toggle);
  };

  const optionsRow = () => {
    return (
      <>
        <NewVisitButton
          handleOnClick={handleOnClickDialogNewVisit}
          handleClose={handleCloseDialogNewVisit}
        />
        <SearchGoogleButton data={data} />
        <OpenMapsButton data={data} />
        <PrintButton />
      </>
    );
  };

  // Drawer list (mobile)
  const drawerList = () => (
    <div
      className="client-file-header__drawer"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {optionsRow()}
    </div>
  );

  const mobileMaxWidth = 760;

  // Menu
  return (
    <>
      <div className="client-file-header__header">
        <div className="client-file-header__header__left">
          {width < mobileMaxWidth ? (
            <>
              <OptionsButton handleOnClick={toggleDrawer} />
              <Drawer
                style={{ width: 300 }}
                anchor="left"
                open={drawer}
                onClose={toggleDrawer(false)}
              >
                {drawerList()}
              </Drawer>
            </>
          ) : (
            <img
              src={logo}
              className="client-file-header__logo"
              alt="Logotipo de Geventas"
            />
          )}
        </div>
        <div className="client-file-header__header__right">
          <CloseButton />
        </div>
      </div>
      {width > mobileMaxWidth ? (
        <div className="client-file-header__options-row">{optionsRow()}</div>
      ) : null}

      <DialogNewVisit
        open={dialogNewVisitOpen}
        data={{
          name: data["CLI_NOMBRE"],
          vat: data["CLI_ID_FISCAL"],
          address: data["CLI_DIRECCION"],
          email: data["INTERLOCUTOR EMAIL"],
        }}
        handleClose={handleCloseDialogNewVisit}
      />
    </>
  );
}

export default Header;
