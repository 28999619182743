import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Switch,
  Divider,
  Select,
  MenuItem,
} from "@material-ui/core";

function FiltersList({ handleSwitch, handleSelect, filters }) {
  return (
    <List>
      <ListItem>
        <ListItemText primary="Filtros" />
      </ListItem>
      <Divider component="li" />
      <Select
        value={filters["category"]}
        onChange={handleSelect}
        className="products__select-input"
        displayEmpty
      >
        <MenuItem value="">Todos</MenuItem>
        <MenuItem value="PHONES">Terminales</MenuItem>
        <MenuItem value="RATES">Tarifas</MenuItem>
        <MenuItem value="TABLETS">Tablets</MenuItem>
        <MenuItem value="LAPTOPS">Portátiles</MenuItem>
        <MenuItem value="ACCESSORIES">Accesorios</MenuItem>
        <MenuItem value="SERVICES">Servicios</MenuItem>
        <MenuItem value="CONNECTIVITY">Conectividad</MenuItem>
        <MenuItem value="WIRINGPOINTS">Puntos de cableado</MenuItem>
        <MenuItem value="SWITCH">Switch</MenuItem>
        <MenuItem value="LANDPHONES">Puestos fijos</MenuItem>
        <MenuItem value="EXTRAS">Extras</MenuItem>
      </Select>

      <ListItem className="products__list-item">
        <ListItemIcon>
          <Switch
            color="primary"
            checked={filters["orderByPrice"]}
            onChange={handleSwitch}
            name="orderByPrice"
          />
        </ListItemIcon>
        <ListItemText secondary="Precio ascendente" />
      </ListItem>
    </List>
  );
}

export default FiltersList;
