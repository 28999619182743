import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Add as AddIcon,
  PermContactCalendar as PermContactCalendarIcon,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { FirebaseContext } from "../Firebase";
import DialogNewVisit from "./DialogNewVisit";

import { Mail } from "@material-ui/icons";

function DialogVisit({ open, data, handleCloseFromDiary, sendEmail }) {
  const firebase = useContext(FirebaseContext);

  const [dialogNewVisitOpen, setDialogNewVisitOpen] = useState(false);
  const [dataObj, setDataObj] = useState(null);
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  // Close this Component
  const handleClose = () => {
    setDataObj(null);
    handleCloseFromDiary();
  };

  // Close this Component but preserve data for child Component
  const handleCloseWithData = () => {
    handleCloseFromDiary();
  };

  // Close child Component
  const handleCloseDialogNewVisit = () => {
    setDataObj(null);
    setDialogNewVisitOpen(false);
  };

  const handleChange = (event, property) => {
    const newData = { ...dataObj };

    if (property === "visited") {
      newData[property] = !newData[property];
    } else {
      newData[property] = event.target.value;
    }

    setDataObj(newData);
  };

  const handleSave = () => {
    const finalObj = {
      info: {
        address: dataObj.address,
        commentary: dataObj.commentary,
        name: dataObj.name,
        vat: dataObj.vat,
        visited: false,
      },
      date: new Date(dataObj.dateChange),
    };

    firebase.updateVisit(dataObj.id, finalObj);

    handleClose();
  };

  const handleAdd = () => {
    handleCloseWithData();
    setDialogNewVisitOpen(true);
  };

  const handleClientFile = () => {
    window.open(`/ficha-cliente/${dataObj.vat}`);
  };

  // Close Alert
  const alertClose = () => {
    setAlertIsOpen(false);
  };

  if (data === null) {
    return (
      <DialogNewVisit
        open={dialogNewVisitOpen}
        data={dataObj}
        handleClose={handleCloseDialogNewVisit}
      />
    );
  } else {
    if (dataObj === null) {
      setDataObj(data);
    }

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={alertIsOpen}
          autoHideDuration={6000}
          onClose={alertClose}
        >
          <Alert onClose={alertClose} severity="error">
            ¡Error al enviar el email!
          </Alert>
        </Snackbar>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" style={{ padding: 8 }}>
                Editar visita
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <div className="dialog__header">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAdd}
              >
                Agendar Nueva Visita
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PermContactCalendarIcon />}
                onClick={handleClientFile}
              >
                Ficha del cliente
              </Button>
            </div>
            <div className="dialog__content">
              <div className="dialog__item">
                <Typography variant="caption" gutterBottom>
                  Empresa
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {dataObj?.name}
                </Typography>
              </div>

              <div className="dialog__item">
                <Typography variant="caption" gutterBottom>
                  NIF
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {dataObj?.vat}
                </Typography>
              </div>

              <div className="dialog__item">
                <Typography variant="caption" gutterBottom>
                  Dirección
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {dataObj?.address}
                </Typography>
              </div>

              <div className="dialog__item">
                <Typography gutterBottom>
                  <TextField
                    id="standard-multiline-flexible"
                    label="Comentario"
                    multiline
                    fullWidth="true"
                    value={dataObj?.commentary}
                    onChange={(event) => handleChange(event, "commentary")}
                  />
                </Typography>
              </div>

              <div className="dialog__item">
                <Typography gutterBottom>
                  <TextField
                    id="datetime-local"
                    label="Fecha"
                    type="datetime-local"
                    defaultValue={dataObj?.dateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => handleChange(event, "dateChange")}
                  />
                </Typography>
              </div>

              <div className="dialog__item">
                <Typography variant="caption" gutterBottom>
                  Estado
                </Typography>
                <Typography gutterBottom>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={dataObj?.visited}
                        onChange={(event) => handleChange(event, "visited")}
                        name="checkedA"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        color="primary"
                      />
                    }
                    label="Visitado"
                  />
                </Typography>
              </div>

              <div className="dialog__item">
                <Typography variant="caption" gutterBottom>
                  Email
                </Typography>
                <Typography gutterBottom>
                  <FormControlLabel
                    control={
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Mail />}
                        onClick={() => sendEmail(dataObj.id)}
                      >
                        Mandar Email
                      </Button>
                    }
                    label=""
                  />
                </Typography>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleSave} color="primary">
              Guardar cambios
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default DialogVisit;
