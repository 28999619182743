import React, { useState, useContext } from "react";
import { Button, Typography, Link, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FirebaseContext } from "../Firebase";
import { useHistory } from "react-router-dom";
import "./style.css";
import logo from "../../logo.png";

function Login() {
  const firebase = useContext(FirebaseContext);

  const [alertOpen, setAlertOpen] = useState(false);
  let history = useHistory();

  const alertClose = () => {
    setAlertOpen(false);
  };

  const signIn = () => {
    firebase
      .signIn()
      .then((__user) => {
        history.push("/agenda");
      })
      .catch((__error) => {
        setAlertOpen(true);
      });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertClose}
      >
        <Alert onClose={alertClose} severity="error">
          ¡Ocurrió un error al iniciar sesión!
        </Alert>
      </Snackbar>

      <div className="container">
        <img src={logo} width="250px;" alt="Logotipo de Geventas" />
        <div className="spacing">
          <Button variant="contained" color="primary" onClick={signIn}>
            Acceder con Google
          </Button>
        </div>
      </div>

      <div className="copyright">
        <Typography variant="body2" color="textPrimary">
          © 2021 -{" "}
          <Link href="http://microshopnet.net" target="_blank" rel="noopener">
            MicroShop
          </Link>
        </Typography>
      </div>
    </>
  );
}

export default Login;
