import React, { useState, useContext } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Menu as MenuIcon, Settings as SettingsIcon } from "@material-ui/icons";
import windowSize from "../../helpers/windowsize";
import { FirebaseContext } from "../Firebase";
import { ThemeModeContext } from "../ThemeMode";
import { useHistory, useLocation } from "react-router-dom";
import "./style.css";
import logo from "../../logo.png";
import localforage from "localforage";

export default function Header() {
  const firebase = useContext(FirebaseContext);
  const { changeThemeMode, themeMode } = useContext(ThemeModeContext);
  const theme = useTheme();

  const history = useHistory();
  const location = useLocation();

  // * Mobile detector
  const { width } = windowSize();
  const isMobile = width < 760 ? true : false;

  // * Main items
  const mainItems = [
    {
      title: "Agenda",
      url: "/agenda",
    },
    {
      title: "Clientes",
      url: "/clientes",
    },
    /* Hide for production upload
    {
      title: "Productos",
      url: "/productos",
    },
    */
  ];

  // * Generate Urls list
  const headerUrlsList = [];

  for (let index = 0; index < mainItems.length; index++) {
    mainItems[index].active =
      mainItems[index].url === location.pathname ? true : false;
    const item = mainItems[index];

    if (isMobile && !item.active) {
      continue;
    }

    headerUrlsList.push(
      <div
        key={index}
        className={item.active ? "header__url active" : "header__url"}
        style={{
          color: theme.palette.primary.main,
        }}
        onClick={() => {
          history.push(item.url);
        }}
      >
        {item.title}
      </div>
    );
  }

  // * Handle Drawer of Sidebar list (only mobile)
  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = (toggle) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(toggle);
  };

  // * Handler Settings Menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // * Header component
  return (
    <div className="header">
      <div className="header__left">
        {isMobile ? (
          <>
            <IconButton aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon color="primary" style={{ fontSize: 28 }} />
            </IconButton>
            <Drawer anchor="left" open={drawer} onClose={toggleDrawer(false)}>
              <div
                className="header__drawer"
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <List>
                  {mainItems.map((itemObj, index) => (
                    <ListItem
                      button
                      key={index}
                      onClick={() => {
                        history.push(itemObj.url);
                      }}
                      selected={itemObj?.active === true}
                    >
                      <ListItemText primary={itemObj.title} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Drawer>
          </>
        ) : (
          <img src={logo} className="header__logo" alt="Logotipo de Geventas" />
        )}
      </div>

      <div className="header__right">
        {headerUrlsList}

        {/* Settings Button */}
        <IconButton
          aria-label="ajustes"
          aria-controls="settings"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <SettingsIcon color="primary" style={{ fontSize: 28 }} />
        </IconButton>

        {/* Settings Menu */}
        <Menu
          id="settings"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              changeThemeMode();
              handleClose();
            }}
          >
            Activar Modo {themeMode === "light" ? "Oscuro" : "Claro"}
          </MenuItem>
          <MenuItem
            onClick={() => {
              localforage.clear().then(() => firebase.signOut());
            }}
          >
            Cerrar sesión
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
