import React, { useEffect, useState, useContext } from "react";
import Header from "../Header";
import { AuthContext } from "../Auth";
import { StorageContext } from "../Storage";
import Loading from "../Loading";
import {
  Drawer,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import "./style.css";
import columnsFn from "./columns";
import initialFilters from "./initialFilters";
// Buttons
import SearchButton from "./Buttons/SearchButton";
import ResetFiltersButton from "./Buttons/ResetFiltersButton";
import ShowFiltersButton from "./Buttons/ShowFiltersButton";
import FiltersList from "./FiltersList";
import ManagerInputs from "./ManagerInputs";
// Dialogs
import DialogAddProduct from "./ModifyProducts/DialogAddProduct";
import DialogEditProduct from "./ModifyProducts/DialogEditProduct";
// Helpers
import permissions from "../../helpers/permissions";
// Moment
import "moment/locale/es";
moment.locale("es");

export default function Products() {
  // Context
  const auth = useContext(AuthContext);
  const storage = useContext(StorageContext);
  const theme = useTheme();
  // Main State
  const [dataLoaded, setDataLoaded] = useState(false);
  const [products, setProducts] = useState(null);
  const [productsFiltered, setProductsFiltered] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const [showFiltersMobile, setShowFiltersMobile] = useState(false);
  const [dialogAddProductOpen, setDialogAddProductOpen] = useState(false);
  const [productRowData, setProductRowData] = useState(null);
  const [dialogEditProductOpen, setDialogEditProductOpen] = useState(false);

  // Windows Size
  const initialSize = window.innerWidth < 760 ? "mobile" : "desktop";
  const [windowSize, setWindowSize] = useState(initialSize);

  useEffect(() => {
    function reportWindowSize() {
      if (window.innerWidth < 760 && windowSize !== "mobile") {
        setWindowSize("mobile");
      } else if (window.innerWidth >= 760 && windowSize !== "desktop") {
        setWindowSize("desktop");
      }
    }

    window.addEventListener("resize", reportWindowSize);

    // Get products from Storage
    (async () => {
      const products = storage?.products;

      if (products) {
        setProducts(products);
        setProductsFiltered(products);
        setDataLoaded(true);
      }
    })();

    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, [storage, windowSize]);

  const columns = columnsFn(windowSize, auth.email);

  function handleDialogAddProductOpen() {
    setDialogAddProductOpen(true);
  }

  function handleDialogEditProductOpen(_event, row) {
    setProductRowData(row);
    setDialogEditProductOpen(true);
  }

  function handleResetFilters() {
    setProductsFiltered(products);
    setFilters(initialFilters);
  }

  function applyFilter(newFilter) {
    // Set filters for fast input response
    setFilters(newFilter);

    let results = [...products];

    // Search filter
    if (newFilter.search !== "") {
      results = results.filter((product) => {
        if (
          product["title"]
            .toLowerCase()
            .includes(newFilter.search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    // Select Category
    if (newFilter["category"] !== "") {
      results = results.filter(
        (product) => product.category === newFilter["category"]
      );
    }

    // Price ascending
    if (newFilter["orderByPrice"] === true) {
      results = results.sort((a, b) => a.price - b.price);
    }

    setProductsFiltered(results);
  }

  function handleSearch({ target }) {
    const newSearch = { ...filters };
    newSearch.search = target.value;
    applyFilter(newSearch);
  }

  function handleSwitch({ target }) {
    const keyFilter = target.name;
    const newFilter = { ...filters };
    newFilter[keyFilter] = !newFilter[keyFilter];
    applyFilter(newFilter);
  }

  function handleSelect({ target }) {
    const newFilter = { ...filters };
    newFilter["category"] = target.value;
    applyFilter(newFilter);
  }

  function handleShowFilters() {
    return setShowFiltersMobile(!showFiltersMobile);
  }

  function handleDialogEditProductClose() {
    setDialogEditProductOpen(false);
  }

  function handleDialogAddProductClose() {
    setDialogAddProductOpen(false);
  }

  if (dataLoaded) {
    // Create rows
    const rows = [...productsFiltered];

    console.log(`productsFiltered`, productsFiltered);

    return (
      <>
        <Header />
        <div className="products__container">
          {windowSize === "desktop" ? (
            <div className="products__left-column">
              <SearchButton
                value={filters["search"]}
                handleOnChange={handleSearch}
              />
              {permissions("manager", auth.email) ? (
                <ManagerInputs
                  handleDialogAddProductOpen={handleDialogAddProductOpen}
                />
              ) : null}
              <ResetFiltersButton handleOnClick={handleResetFilters} />
              <FiltersList
                handleSelect={handleSelect}
                handleSwitch={handleSwitch}
                filters={filters}
              />
            </div>
          ) : (
            <div className="products__center-column">
              <ShowFiltersButton handleOnClick={handleShowFilters} />
              <Drawer
                style={{ width: 300 }}
                anchor="right"
                open={showFiltersMobile}
                onClose={handleShowFilters}
              >
                <div className="products__drawer-container">
                  <SearchButton
                    value={filters["search"]}
                    handleOnChange={handleSearch}
                  />
                  {permissions("manager", auth.email) ? (
                    <ManagerInputs
                      handleDialogAddProductOpen={handleDialogAddProductOpen}
                    />
                  ) : null}
                  <ResetFiltersButton handleOnClick={handleResetFilters} />
                  <FiltersList handleSwitch={handleSwitch} filters={filters} />
                </div>
              </Drawer>
            </div>
          )}
          <div className="products__right-column">
            <div
              className="products__table-info"
              style={{ color: theme.palette.primary.main }}
            >
              Mostrando{" "}
              <span className="products__table-info--important">
                {productsFiltered.length}
              </span>{" "}
              de{" "}
              <span className="products__table-info--important">
                {products.length}
              </span>{" "}
              productos
            </div>
            <Paper className="products__table-paper">
              <TableContainer className="products__table-container">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className="diary__table-head"
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: theme.palette.primary.main,
                            borderBottom: `1px solid ${theme.table.border}`,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        onClick={(event) =>
                          handleDialogEditProductOpen(event, row)
                        }
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className="diary__table-row"
                              style={{
                                borderBottom: `1px solid ${theme.table.border}`,
                              }}
                            >
                              {column.format ? column.format(row) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>

        <DialogAddProduct
          open={dialogAddProductOpen}
          handleCloseFromProducts={handleDialogAddProductClose}
        />

        <DialogEditProduct
          open={dialogEditProductOpen}
          data={productRowData}
          handleCloseFromProducts={handleDialogEditProductClose}
        />
      </>
    );
  } else {
    return (
      <>
        <Header />
        <Loading />
      </>
    );
  }
}
