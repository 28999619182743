import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  TextField,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import { FirebaseContext } from "../Firebase";
import { AuthContext } from "../Auth";
import { StorageContext } from "../Storage";

// When mounted from ClientFile, gets data directly from Clients collection, when mounted from DialogVisit, gets data from Visits collection

function DialogNewVisit({ open, data, handleClose }) {
  const firebase = useContext(FirebaseContext);
  const auth = useContext(AuthContext);
  const storage = useContext(StorageContext);

  const [dataObj, setDataObj] = useState(null);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    (async () => {
      if (data !== null && dataObj === null) {
        const newDataObj = { ...data };
        newDataObj.commentary = "";
        newDataObj.dateChange = null;

        // First get email from Clients Collection
        newDataObj.email = "";
        const vat = newDataObj.vat;

        const clients = storage?.clients;

        for (let index = 0; index < clients.length; index++) {
          const client = clients[index];
          if (client["CLI_ID_FISCAL"] === vat) {
            newDataObj.email = client["INTERLOCUTOR EMAIL"];
            break;
          }
        }

        // Second get email from Emails Collection
        const emailDoc = await firebase.getEmail(newDataObj.vat);
        if (emailDoc) {
          newDataObj.email = emailDoc.email;
        }

        setDataObj(newDataObj);
      }
    })();
  });

  const alertClose = () => {
    setAlertIsOpen(false);
  };

  const handleChange = (event, property) => {
    const newData = { ...dataObj };

    newData[property] = event.target.value;

    setDataObj(newData);
  };

  const validation = () => {
    if (!dataObj.dateChange) {
      setAlertMessage("¡Debes seleccionar una Fecha!");
      setAlertIsOpen(true);
      return false;
    } else {
      return true;
    }
  };

  const handleSave = () => {
    if (validation()) {
      // New visit handle
      const finalObj = {
        info: {
          address: dataObj.address,
          commentary: dataObj.commentary,
          name: dataObj.name,
          vat: dataObj.vat,
          visited: false,
        },
        date: new Date(dataObj.dateChange),
        user: auth.email,
      };

      firebase.addVisit(finalObj);

      // Email handle
      if (dataObj.email !== "") {
        firebase.setEmail(dataObj.vat, { email: dataObj.email });
      }

      handleClose();
    }
  };

  if (dataObj) {
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={alertIsOpen}
          autoHideDuration={6000}
          onClose={alertClose}
        >
          <Alert onClose={alertClose} severity="error">
            {alertMessage}
          </Alert>
        </Snackbar>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" style={{ padding: 8 }}>
                Nueva visita
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <div className="dialog__content">
              <div className="dialog__item">
                <Typography variant="caption" gutterBottom>
                  Empresa
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {dataObj?.name}
                </Typography>
              </div>

              <div className="dialog__item">
                {" "}
                <Typography variant="caption" gutterBottom>
                  NIF
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {dataObj?.vat}
                </Typography>
              </div>

              <div className="dialog__item">
                <Typography variant="caption" gutterBottom>
                  Dirección
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {dataObj?.address}
                </Typography>
              </div>

              <div className="dialog__item">
                <Typography gutterBottom>
                  <TextField
                    id="datetime-local"
                    label="Fecha*"
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => handleChange(event, "dateChange")}
                  />
                </Typography>
              </div>

              <div className="dialog__item">
                <Typography gutterBottom>
                  <TextField
                    id="standard-multiline-flexible"
                    label="Comentario"
                    fullWidth
                    multiline
                    rowsMax={4}
                    onChange={(event) => handleChange(event, "commentary")}
                  />
                </Typography>
              </div>

              <div className="dialog__item">
                <Typography gutterBottom>
                  <TextField
                    label="Email"
                    fullWidth
                    defaultValue={dataObj?.email}
                    onChange={(event) => handleChange(event, "email")}
                  />
                </Typography>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleSave} color="primary">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}

export default DialogNewVisit;
