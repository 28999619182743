import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// Feature Components
import ProtectedRoute from "./ProtectedRoute";
import Loading from "../Loading";
import NoMatch from "../NoMatch";
import Login from "../Login";
// Page Components
import Diary from "../Diary";
import Clients from "../Clients";
import ClientFile from "../ClientFile";
import Products from "../Products";
import Budgets from "../Budgets";

export default function Router({ user, loading }) {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            /* Special loading checker */
            if (user === null) {
              return <Loading />;
            } else {
              if (user) {
                return <Redirect to="/agenda" />;
              } else {
                return <Login />;
              }
            }
          }}
        />
        <ProtectedRoute
          path="/agenda"
          userData={user}
          loading={loading}
          role="user"
        >
          <Diary />
        </ProtectedRoute>
        <ProtectedRoute
          path="/clientes"
          userData={user}
          loading={loading}
          role="user"
        >
          <Clients />
        </ProtectedRoute>
        <ProtectedRoute
          path="/ficha-cliente/:vat"
          userData={user}
          loading={loading}
          role="user"
        >
          <ClientFile />
        </ProtectedRoute>
        <ProtectedRoute
          path="/productos"
          userData={user}
          loading={loading}
          role="user"
        >
          <Products />
        </ProtectedRoute>
        <ProtectedRoute
          path="/ofertas"
          userData={user}
          loading={loading}
          role="user"
        >
          <Budgets />
        </ProtectedRoute>
        <Route path="*" component={NoMatch} />
      </Switch>
    </BrowserRouter>
  );
}
