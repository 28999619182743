import React from "react";
import { Button } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

function OptionsButton({ handleOnClick }) {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<MenuIcon />}
      onClick={handleOnClick(true)}
    >
      Opciones
    </Button>
  );
}

export default OptionsButton;
