import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import {
  SkipPrevious as SkipPreviousIcon,
  SkipNext as SkipNextIcon,
} from "@material-ui/icons";
// Context
import { FirebaseContext } from "../Firebase";
import { AuthContext } from "../Auth";
import { StorageContext } from "../Storage";
import { DiaryDateContext } from "../Diary/DiaryDateContext";
// Components
import Header from "../Header";
import Loading from "../Loading";
import DialogVisit from "./DialogVisit";
import { sendAppointmentEmail } from "../Mailing";
// Helpers
import columnsFn from "./columns";
import infoWithGmail from "../../helpers/infoWithGmail";
// Themes
import "./style.css";
// Moment
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

export default function Budgets() {
  const firebase = useContext(FirebaseContext);
  const auth = useContext(AuthContext);
  const storage = useContext(StorageContext);
  const { diaryDate, updateDiaryDate } = useContext(DiaryDateContext);
  const theme = useTheme();

  const [dialogVisitOpen, setDialogVisitOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [visits, setVisits] = useState(null);
  const [visitData, setVisitData] = useState(null);

  const initialSize = window.innerWidth < 760 ? "mobile" : "desktop";
  const [windowSize, setWindowSize] = useState(initialSize);

  // * Adjust Window
  useEffect(() => {
    function reportWindowSize() {
      // * 960px instead of 760px because the table has too much columns
      if (window.innerWidth < 960 && windowSize !== "mobile") {
        setWindowSize("mobile");
      } else if (window.innerWidth >= 960 && windowSize !== "desktop") {
        setWindowSize("desktop");
      }
    }

    window.addEventListener("resize", reportWindowSize);

    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, [windowSize]);

  // * Get visits
  useEffect(() => {
    // Get visits from Storage context
    const visits = storage?.visits;

    if (visits) {
      setVisits(visits);
    }
  }, [storage]);

  function handleDialogVisitOpen(event, row) {
    if (event.target.type === "checkbox") {
      return;
    }

    setVisitData(row);
    setDialogVisitOpen(true);
  }

  function handleDialogVisitClose() {
    setVisitData(null);
    setDialogVisitOpen(false);
  }

  function createData({
    user,
    info: { address, commentary, name, vat, visited },
  }) {
    return {
      address,
      commentary,
      name,
      vat,
      visited,
      comercial: infoWithGmail(user).name,
    };
  }

  const sendEmail = async (id) => {
    // First get data from visit
    const visit = visits.filter((visit) => visit["id"] === id)[0];

    // Get email from Clients Collection
    let clientEmail = "";
    const vat = visit.info.vat;

    const clients = storage?.clients;

    for (let index = 0; index < clients.length; index++) {
      const client = clients[index];
      if (client["CLI_ID_FISCAL"] === vat) {
        clientEmail = client["INTERLOCUTOR EMAIL"];
        break;
      }
    }

    // Get email from Emails Collection
    const emailDoc = await firebase.getEmail(vat);

    if (emailDoc) {
      clientEmail = emailDoc.email;
    }

    if (clientEmail !== "") {
      const finalDate = `${moment(visit.date).format("DD")} de ${moment(
        visit.date
      ).format("MMMM")}`;

      const clientName = visit.info.name;

      const result = await sendAppointmentEmail(
        firebase,
        auth.email,
        clientEmail,
        { clientName, finalDate }
      );

      if (result === false) {
        setAlertMessage({
          text: "Ocurrió un error al enviar el email",
          severity: "error",
        });
        setAlertIsOpen(true);
      } else {
        setAlertMessage({
          text: "Se ha enviado un email al cliente",
          severity: "success",
        });
        setAlertIsOpen(true);
      }
    }
  };

  function alertClose() {
    setAlertIsOpen(false);
  }

  function handlePreviousDay() {
    setVisits(null);
    updateDiaryDate(diaryDate.subtract(1, "days"));
  }

  function handleNextDay() {
    setVisits(null);
    updateDiaryDate(diaryDate.add(1, "days"));
  }

  function handleVisited(visited, id) {
    firebase.updateVisit(id, { "info.visited": !visited });
    if (!visited) {
      sendEmail(id);
    }
  }

  // * Generate Columns
  const columns = columnsFn(windowSize, auth.email, handleVisited);

  // * Generate Rows
  let rows = null;

  if (visits) {
    rows = [];

    visits.forEach((visit) => {
      const date = moment(visit.date);

      if (date.isSame(diaryDate, "day")) {
        const data = { ...createData(visit) };
        data.id = visit.id;
        data.hour = date.format("HH:mm");
        data.dateChange = date.format("YYYY-MM-DDTHH:mm");
        rows.push(data);
      } else {
        return;
      }
    });

    rows.sort((a, b) => {
      if (a.hour < b.hour) {
        return -1;
      }
      if (a.hour > b.hour) {
        return 1;
      }
      return 0;
    });

    rows.sort((a, b) => {
      if (a.comercial < b.comercial) {
        return -1;
      }
      if (a.comercial > b.comercial) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertIsOpen}
        autoHideDuration={6000}
        onClose={alertClose}
      >
        <Alert onClose={alertClose} severity={alertMessage.severity}>
          {alertMessage.text}
        </Alert>
      </Snackbar>
      <Header />
      <div className="diary__container">
        <div className="diary__table-header">
          <div className="diary__table-header__button">
            <Button
              variant="contained"
              color="primary"
              startIcon={<SkipPreviousIcon />}
              onClick={handlePreviousDay}
            >
              Día anterior
            </Button>
          </div>
          <div className="diary__table-header__button">
            <Button
              variant="contained"
              color="primary"
              startIcon={<SkipNextIcon />}
              onClick={handleNextDay}
            >
              Día siguiente
            </Button>
          </div>
          <div
            className="diary__table-header__info"
            style={{ color: theme.palette.primary.main }}
          >
            Mostrando{" "}
            <span className="diary__table-header__important">
              {diaryDate.format("DD MMMM 'YY")}
            </span>
          </div>
        </div>
        <Paper>
          <TableContainer className="diary__table-container">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className="diary__table-head"
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: theme.palette.primary.main,
                        borderBottom: `1px solid ${theme.table.border}`,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows !== null ? (
                  rows.map((row, index) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      onClick={(event) => handleDialogVisitOpen(event, row)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className="diary__table-row"
                            style={{
                              color: theme.palette.primary.main,
                              borderBottom: `1px solid ${theme.table.border}`,
                            }}
                          >
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                ) : (
                  <Loading />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <DialogVisit
        open={dialogVisitOpen}
        data={visitData}
        handleCloseFromDiary={handleDialogVisitClose}
      />
    </>
  );
}
