import React from "react";
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

export default function GroupRates({ values, handleChange }) {
  if (values["category"] === "RATES") {
    return (
      <>
        <InputLabel id="value">Valor *</InputLabel>
        <Select
          labelId="value"
          name="valuePhones"
          onChange={handleChange}
          value={values.valuePhones}
        >
          <MenuItem value="LOW">Bajo</MenuItem>
          <MenuItem value="MEDIUM">Medio</MenuItem>
          <MenuItem value="HIGH">Alto</MenuItem>
        </Select>
        <Typography gutterBottom paragraph>
          <TextField
            label="Llamadas *"
            name="specsCallsRates"
            fullWidth
            onChange={handleChange}
            value={values.specsCallsRates}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Internet *"
            name="specsInternetRates"
            fullWidth
            onChange={handleChange}
            value={values.specsInternetRates}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Otros (1)"
            name="specsOthers1Rates"
            fullWidth
            onChange={handleChange}
            value={values.specsOthers1Rates}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Otros (2)"
            name="specsOthers2Rates"
            fullWidth
            onChange={handleChange}
            value={values.specsOthers2Rates}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Otros (3)"
            name="specsOthers3Rates"
            fullWidth
            onChange={handleChange}
            value={values.specsOthers3Rates}
          />
        </Typography>
      </>
    );
  } else {
    return null;
  }
}
