import React, { useEffect, useState, useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import Header from "./Header";
import Info from "./Info";
import Tags from "./Tags";
import { StorageContext } from "../Storage";

function ClientFile() {
  // Context
  const storage = useContext(StorageContext);
  const theme = useTheme();
  // Params
  const { vat } = useParams();

  // Get client data from Storage (filtering the client)
  const [data, setData] = useState(null);
  const clients = storage?.clients;
  const customClients = storage?.customClients;

  useEffect(() => {
    if (clients !== undefined) {
      // Get clients and customclients from Local forage
      let allClients = clients;

      // For avoid error when customClients is empty
      if (customClients !== undefined) {
        allClients = clients.concat(customClients);
      }

      if (allClients) {
        const clientData = allClients.filter(
          (client) => client["CLI_ID_FISCAL"] === vat
        );

        // * This occurs when is a customClient but storage.customClients is still undefined, so skip untill customClients is loaded
        if (clientData.length !== 0) {
          setData(clientData[0]);
        }
      }
    }
  }, [clients, customClients, storage, vat]);

  if (data !== null) {
    return (
      <>
        <Header data={data} />
        <div className="client-file-content">
          <Info data={data} />
          <hr
            className="client-file-info__separator"
            style={{ borderTop: `1px dashed ${theme.palette.primary.main}` }}
          />
          <Tags data={data} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}

export default ClientFile;
