import React, { useContext } from "react";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import { FirebaseContext } from "../Firebase";
import "./style.css";
import logo from "../../logo.png";

function Forbidden() {
  const firebase = useContext(FirebaseContext);

  const signOut = () => {
    firebase.signOut();
  };

  return (
    <div className="forbidden__container">
      <img src={logo} width="250px;" alt="Logotipo de Geventas" />
      <Alert className="forbidden__alert-block" severity="error">
        ¡No tienes permiso para ver esta página!
      </Alert>
      <Button variant="contained" color="primary" onClick={signOut}>
        Cerrar sesión
      </Button>
    </div>
  );
}

export default Forbidden;
