import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

function SearchButton({ handleOnChange, value }) {
  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item>
        <SearchIcon color="primary" />
      </Grid>
      <Grid item>
        <TextField
          label="Buscar"
          color="primary"
          value={value}
          onChange={handleOnChange}
          className="clients__search-input"
        />
      </Grid>
    </Grid>
  );
}

export default SearchButton;
