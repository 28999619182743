import React from "react";
import { Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

export default function AddProductButton({ handleOnClick }) {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={handleOnClick}
      className="products__add-custom-client-button"
    >
      Añadir Producto
    </Button>
  );
}
