const themeLight = {
  palette: {
    primary: {
      light: "#6ce8ff",
      main: "#1eb6e5",
      dark: "#0086b3",
      contrastText: "#fff",
    },
    text: {
      primary: "#1eb6e5",
    },
    background: { default: "#fff", paper: "#fff" },
  },
  table: {
    border: "rgba(224, 224, 224, 1)",
  },
  overrides: {
    MuiTableRow: {
      root: {
        "&.MuiTableRow-hover:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
  },
};

export default themeLight;
