import React from "react";
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
} from "@material-ui/core";

export default function GroupPhones({ values, handleChange }) {
  if (values["category"] === "PHONES") {
    return (
      <>
        <InputLabel id="value">Valor *</InputLabel>
        <Select
          labelId="value"
          name="valuePhones"
          onChange={handleChange}
          value={values.valuePhones}
        >
          <MenuItem value="LOW">Bajo</MenuItem>
          <MenuItem value="MEDIUM">Medio</MenuItem>
          <MenuItem value="HIGH">Alto</MenuItem>
        </Select>
        <Typography gutterBottom paragraph>
          <TextField
            label="Pantalla *"
            name="specsDisplayPhones"
            fullWidth
            onChange={handleChange}
            value={values.specsDisplayPhones}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Almacenamiento *"
            type="number"
            name="specsInternalStoragePhones"
            fullWidth
            onChange={handleChange}
            value={values.specsInternalStoragePhones}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">GB</InputAdornment>
              ),
            }}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="RAM *"
            type="number"
            name="specsRamStoragePhones"
            fullWidth
            onChange={handleChange}
            value={values.specsRamStoragePhones}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">GB</InputAdornment>
              ),
            }}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Cámara *"
            name="specsCameraPhones"
            fullWidth
            onChange={handleChange}
            value={values.specsCameraPhones}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="URL Relativa de Imagen *"
            name="imageUrlPhones"
            fullWidth
            onChange={handleChange}
            value={values.imageUrlPhones}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="URL Más Info *"
            name="moreInfoUrlPhones"
            fullWidth
            onChange={handleChange}
            value={values.moreInfoUrlPhones}
          />
        </Typography>
      </>
    );
  } else {
    return null;
  }
}
