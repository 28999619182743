import React from "react";
import { Button } from "@material-ui/core";
import { Print as PrintIcon } from "@material-ui/icons";

function PrintButton() {
  const handleOnClick = () => {
    window.print();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<PrintIcon />}
      onClick={handleOnClick}
    >
      Imprimir
    </Button>
  );
}

export default PrintButton;
