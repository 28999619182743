const themeGlobal = {
  typography: {
    body1: {
      textTransform: "uppercase",
      fontFamily: "Oswald",
    },
    h6: {
      textTransform: "uppercase",
      fontFamily: "Oswald",
    },
    caption: {
      textTransform: "uppercase",
      fontFamily: "Oswald",
      color: "#5cc4e3",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          userSelect: "none",
        },
      },
    },
    MuiListItemText: {
      primary: {
        textTransform: "uppercase",
        fontFamily: "Oswald",
        fontSize: 18,
        color: "#1eb6e5",
      },
      secondary: {
        textTransform: "uppercase",
        fontFamily: "Oswald",
        color: "#1eb6e5",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#a9ebff",
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: "#a9ebff",
      },
      switchBase: {
        color: "#bff0ff",
      },
    },
    MuiTextField: {
      root: {
        "& .MuiInput-underline:before": {
          borderBottomColor: "#a9ebff",
        },
        "& .MuiInput-input": {
          textTransform: "uppercase",
          fontFamily: "Oswald",
        },
      },
    },
    MuiFormLabel: {
      root: {
        textTransform: "uppercase",
        fontFamily: "Oswald",
        color: "#5cc4e3",
      },
    },
    MuiButton: {
      label: {
        textTransform: "uppercase",
        fontFamily: "Oswald",
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottomColor: "#a9ebff",
        },
      },
    },
    MuiDialog: {
      paperWidthSm: {
        width: 680,
      },
    },
  },
};

export default themeGlobal;
