import React from "react";
import { Button } from "@material-ui/core";
import { FindInPage as FindInPageIcon } from "@material-ui/icons";

function SearchGoogleButton({ data }) {
  const handleOnClick = () => {
    window.open(
      `https://google.es/search?q=${data["CLI_ID_FISCAL"]}+${data["CLI_NOMBRE"]}`,
      "_blank"
    );
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<FindInPageIcon />}
      onClick={handleOnClick}
    >
      Buscar en Google
    </Button>
  );
}

export default SearchGoogleButton;
