import React from "react";
import { Button } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

function ResetFiltersButton({ handleOnClick }) {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<DeleteIcon />}
      onClick={handleOnClick}
      className="products__reset-filters-button"
    >
      Restablecer filtros
    </Button>
  );
}

export default ResetFiltersButton;
