const config = {
  apiKey: "AIzaSyBMeC2yAQOY2WWU1TAHzijpBzptdLSQ_cI",
  authDomain: "geventas-2aba7.firebaseapp.com",
  databaseURL: "https://geventas-2aba7.firebaseio.com",
  projectId: "geventas-2aba7",
  storageBucket: "geventas-2aba7.appspot.com",
  messagingSenderId: "634842061307",
  appId: "1:634842061307:web:00b85feb5a345fe8bfd35e",
  measurementId: "G-D1LNTRP817",
};

export default config;
