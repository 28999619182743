export default function infoWithGmail(gmail) {
  if (gmail === "luisgalan67@gmail.com") {
    return {
      name: "Luis",
      fullName: "Luis Galán",
      email: "luis@microshopnet.net",
      phone: "682 264 993",
    };
  } else if (gmail === "diegomicroshop@gmail.com") {
    return {
      name: "Diego",
      fullName: "José Diego Domínguez",
      email: "diego@microshopnet.net",
      phone: "639 843 010",
    };
  } else if (gmail === "javiermicroshop@gmail.com") {
    return {
      name: "Javier",
      fullName: "Javier Jesús Mateos",
      email: "javier@microshopnet.net",
      phone: "689 110 737",
    };
  } else if (gmail === "juancarlosmicroshop@gmail.com") {
    return {
      name: "Juan Carlos",
      fullName: "Juan Carlos de Castro",
      email: "juancarlos@microshopnet.net",
      phone: "616 481 284",
    };
  } else if (gmail === "maitemicroshop@gmail.com") {
    return {
      name: "Maite",
      fullName: "María Teresa García Martín",
      email: "maite@microshopnet.net",
      phone: "651 869 936",
    };
  } else if (gmail === "elenaolivaresmicroshop@gmail.com") {
    return {
      name: "Elena",
      fullName: "Elena Olivares",
      email: "elenaolivares@microshopnet.net",
      phone: "682 366 362",
    };
  } else if (gmail === "juanantoniocalderon32@gmail.com") {
    return {
      name: "Juan Antonio",
      fullName: "Juan Antonio Calderón",
      email: "juanantonio@microshopnet.net",
      phone: "646 571 799",
    };
  } else if (gmail === "stevemarcelomicroshop@gmail.com") {
    return {
      name: "Steve",
      fullName: "Steve Marcelo Argotti",
      email: "stevemarcelo@microshopnet.net",
      phone: "676 971 721",
    };
  } else {
    return {
      name: "Administrador",
      fullName: "Administrador",
      email: "webmaster@microshopnet.net",
      phone: "923 500 500",
    };
  }
}
