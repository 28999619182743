import React from "react";
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
} from "@material-ui/core";

export default function GroupTablets({ values, handleChange }) {
  if (values["category"] === "TABLETS") {
    return (
      <>
        <InputLabel id="value">Valor *</InputLabel>
        <Select
          labelId="value"
          name="valuePhones"
          onChange={handleChange}
          value={values.valuePhones}
        >
          <MenuItem value="LOW">Bajo</MenuItem>
          <MenuItem value="MEDIUM">Medio</MenuItem>
          <MenuItem value="HIGH">Alto</MenuItem>
        </Select>
        <Typography gutterBottom paragraph>
          <TextField
            label="Pantalla *"
            name="specsDisplayTablets"
            fullWidth
            onChange={handleChange}
            value={values.specsDisplayTablets}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Almacenamiento *"
            type="number"
            name="specsInternalStorageTablets"
            fullWidth
            onChange={handleChange}
            value={values.specsInternalStorageTablets}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">GB</InputAdornment>
              ),
            }}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="RAM *"
            type="number"
            name="specsRamStorageTablets"
            fullWidth
            onChange={handleChange}
            value={values.specsRamStorageTablets}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">GB</InputAdornment>
              ),
            }}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Otros (1) *"
            name="specsOthers1Tablets"
            fullWidth
            onChange={handleChange}
            value={values.specsOthers1Tablets}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Otros (2) *"
            name="specsOthers2Tablets"
            fullWidth
            onChange={handleChange}
            value={values.specsOthers2Tablets}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="URL Relativa de Imagen *"
            name="imageUrlTablets"
            fullWidth
            onChange={handleChange}
            value={values.imageUrlTablets}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="URL Más Info *"
            name="moreInfoUrlTablets"
            fullWidth
            onChange={handleChange}
            value={values.moreInfoUrlTablets}
          />
        </Typography>
      </>
    );
  } else {
    return null;
  }
}
