import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

function Info({ data }) {
  const theme = useTheme();

  const infoRow = ({ description, info }, index) => {
    return (
      <div key={index}>
        <span className="client-file-info__description">{description}:</span>{" "}
        <span
          className="client-file-info__info"
          style={{ color: theme.palette.primary.main }}
        >
          {info}
        </span>
      </div>
    );
  };

  const blockA = [
    {
      description: "Asignado a",
      info: data["COMERCIAL"],
    },
    {
      description: "Código FV",
      info: data["DIS_CODIGO_FV"].slice(5),
    },
    {
      description: "Dirección",
      info: data["CLI_DIRECCION"],
    },
    {
      description: "Población",
      info: data["CLI_POBLACION"],
    },
  ];

  const blockB = [
    {
      description: "Interlocutor",
      info: data["Interlocutor"],
    },
    {
      description: "Núm. Fijo",
      info: data["INTERLOCUTOR FIJO"],
    },
    {
      description: "Núm. Móvil",
      info: data["INTERLOCUTOR MOVIL"],
    },
    {
      description: "Email",
      info: data["INTERLOCUTOR EMAIL"],
    },
  ];

  const infoList = (blockData) => {
    const rows = [];

    blockData.forEach((row, index) => {
      rows.push(infoRow(row, index));
    });

    return rows;
  };

  return (
    <div
      className="client-file-info"
      style={{ color: theme.palette.primary.main }}
    >
      <Typography variant="h6" className="client-file-info__title">
        <span style={{ fontWeight: "bold" }}>{data["CLI_NOMBRE"]}</span> |{" "}
        {data["CLI_ID_FISCAL"]}
      </Typography>
      <div className="client-file-info__block">{infoList(blockA)}</div>
      <div className="client-file-info__block">{infoList(blockB)}</div>
    </div>
  );
}

export default Info;
