import React from "react";
import { Typography, TextField } from "@material-ui/core";

export default function GroupServices({ values, handleChange }) {
  if (values["category"] === "SERVICES") {
    return (
      <>
        <Typography gutterBottom paragraph>
          <TextField
            label="Descripción *"
            name="specsDescriptionServices"
            fullWidth
            onChange={handleChange}
            value={values.specsDescriptionServices}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Otros (1) *"
            name="specsOthers1Services"
            fullWidth
            onChange={handleChange}
            value={values.specsOthers1Services}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Otros (2) *"
            name="specsOthers2Services"
            fullWidth
            onChange={handleChange}
            value={values.specsOthers2Services}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="Otros (3) *"
            name="specsOthers3Services"
            fullWidth
            onChange={handleChange}
            value={values.specsOthers3Services}
          />
        </Typography>
        <Typography gutterBottom paragraph>
          <TextField
            label="URL Relativa de Vídeo *"
            name="videoUrlServices"
            fullWidth
            onChange={handleChange}
            value={values.videoUrlServices}
          />
        </Typography>
      </>
    );
  } else {
    return null;
  }
}
