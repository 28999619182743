import infoWithGmail from "../../helpers/infoWithGmail";
import * as yup from "yup";
import axios from "axios";

const sendAppointmentEmail = async (
  firebase,
  gmail,
  recipient,
  appointmentDetails
) => {
  // Validate email recipient
  let schema = yup.object().shape({
    recipient: yup.string().email(),
  });

  const valid = await schema.isValid({
    recipient,
  });

  if (!valid) {
    return false;
  }

  // Seller details
  // TODO: send email from real email
  const fullName = infoWithGmail(gmail).fullName;
  const email = infoWithGmail(gmail).email;
  const phone = infoWithGmail(gmail).phone;

  const idToken = await firebase.getIdToken();
  const fields = {
    from: `${fullName} <no-contestar@microshopnet.net>`,
    to: recipient,
    bcc: "",
    subject: `Tiene una visita concertada con su comercial de Movistar`,
    text: `Estimado/s ${appointmentDetails.clientName},

    Su comercial de Movistar, ${fullName}, le atendió el día ${appointmentDetails.date}. Se le enviará otro correo electrónico para valorar la atención de su comercial durante la visita. Es muy importante para nosotros conocer su opinión.

    Para agendar una nueva cita o realizar cualquier consulta a su comercial, puede contactar con él a través de:
    -	Teléfono: ${phone}
    -	Email: ${email}
    En caso de no estar disponible, puede contactar directamente con nosotros a través de:
    -	Teléfono: 923 500 500
    -	Email: asesoramiento@microshopnet.net

    También puede ver nuestra oferta a través de la página web: https://www.microsalamanca.es/

    Atentamente,
    El Departamento de Gestión de MicroShop.

    AVISO LEGAL: Este mensaje y sus archivos adjuntos van dirigidos exclusivamente a su destinatario, pudiendo contener información confidencial sometida a secreto profesional. No está permitida su comunicación, reproducción o distribución sin la autorización expresa de MICRO INFOR SALAMANCA, S.L.. Si usted no es el destinatario final, por favor elimínelo e infórmenos por esta vía. PROTECCIÓN DE DATOS: De conformidad con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril (GDPR) y la Ley Orgánica 3/2018 de 5 de diciembre (LOPDGDD), le informamos que los datos personales y dirección de correo electrónico del interesado, serán tratados bajo la responsabilidad de MICRO INFOR SALAMANCA, S.L. por un interés legítimo y para el envío de comunicaciones sobre nuestros productos y servicios y se conservarán mientras ninguna de las partes se oponga a ello. Los datos no serán comunicados a terceros, salvo obligación legal. Le informamos que puede ejercer los derechos de acceso, rectificación, portabilidad y supresión de sus datos y los de limitación y oposición a su tratamiento dirigiéndose a Avda/ Mª Auxiliadora, Nº37 - 37004 Salamanca (Salamanca). Email: administracion@microshopnet.net. Si considera que el tratamiento no se ajusta a la normativa vigente, podrá presentar una reclamación ante la autoridad de control en www.aepd.es. Si desea dar de baja este correo electrónico de futuras notificaciones, por favor, mande un mensaje con el asunto BAJA al email: administracion@microshopnet.net. Procesaremos su solicitud con la mayor brevedad posible.
    `,
    html: `<p>Estimado/s ${appointmentDetails.clientName},<p>

    <p>Su comercial de Movistar, ${fullName}, le atendió el día ${appointmentDetails.finalDate}.
    Se le enviará otro correo electrónico para valorar la atención de su comercial durante la visita. Es muy importante para nosotros conocer su opinión.</p>

    <p>
    Para agendar una nueva cita o realizar cualquier consulta a su comercial, puede contactar con él a través de:<br>
    -	Teléfono: ${phone}<br>
    -	Email: <a mailto="${email}">${email}</a>
    </p>
    <p>
    En caso de no estar disponible, puede contactar directamente con nosotros a través de:<br>
    -	Teléfono: 923 500 500<br>
    -	Email: <a mailto="asesoramiento@microshopnet.net">asesoramiento@microshopnet.net</a><br>
    </p>

    <p>
    También puede ver nuestra oferta a través de la página web: <a href="https://www.microsalamanca.es/">https://www.microsalamanca.es/</a>
    </p>

    <p>
    Atentamente,<br>
    El Departamento de Gestión de MicroShop.
    </p>

    <p style="font-size: 11px;">
    AVISO LEGAL: Este mensaje y sus archivos adjuntos van dirigidos exclusivamente a su destinatario, pudiendo contener información confidencial sometida a secreto profesional. No está permitida su comunicación, reproducción o distribución sin la autorización expresa de MICRO INFOR SALAMANCA, S.L.. Si usted no es el destinatario final, por favor elimínelo e infórmenos por esta vía. PROTECCIÓN DE DATOS: De conformidad con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril (GDPR) y la Ley Orgánica 3/2018 de 5 de diciembre (LOPDGDD), le informamos que los datos personales y dirección de correo electrónico del interesado, serán tratados bajo la responsabilidad de MICRO INFOR SALAMANCA, S.L. por un interés legítimo y para el envío de comunicaciones sobre nuestros productos y servicios y se conservarán mientras ninguna de las partes se oponga a ello. Los datos no serán comunicados a terceros, salvo obligación legal. Le informamos que puede ejercer los derechos de acceso, rectificación, portabilidad y supresión de sus datos y los de limitación y oposición a su tratamiento dirigiéndose a Avda/ Mª Auxiliadora, Nº37 - 37004 Salamanca (Salamanca). Email: administracion@microshopnet.net. Si considera que el tratamiento no se ajusta a la normativa vigente, podrá presentar una reclamación ante la autoridad de control en www.aepd.es. Si desea dar de baja este correo electrónico de futuras notificaciones, por favor, mande un mensaje con el asunto BAJA al email: administracion@microshopnet.net. Procesaremos su solicitud con la mayor brevedad posible.
    </p>
    `,
  };

  try {
    await axios.post(
      `${process.env.REACT_APP_SERVER}/api/mail/sendAppointment`,
      {
        idToken,
        fields,
      }
    );
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export { sendAppointmentEmail };
