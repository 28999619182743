import * as yup from "yup";

export default yup.object().shape({
  // * The validation is in reverse order: last the first
  // Conditional values
  // Extras
  specsDescriptionExtras: yup.string().when("category", {
    is: (val) => val === "EXTRAS",
    then: yup.string().required("¡Completa la Descripción!"),
  }),
  // Land Phones
  specsDescriptionLandPhones: yup.string().when("category", {
    is: (val) => val === "LANDPHONES",
    then: yup.string().required("¡Completa la Descripción!"),
  }),
  // Switch
  specsDescriptionSwitch: yup.string().when("category", {
    is: (val) => val === "SWITCH",
    then: yup.string().required("¡Completa la Descripción!"),
  }),
  // Wiring Points
  specsDescriptionWiringPoints: yup.string().when("category", {
    is: (val) => val === "WIRINGPOINTS",
    then: yup.string().required("¡Completa la Descripción!"),
  }),
  // Connectivity
  specsDescriptionConnectivity: yup.string().when("category", {
    is: (val) => val === "CONNECTIVITY",
    then: yup.string().required("¡Completa la Descripción!"),
  }),
  typeConnectivity: yup.string().when("category", {
    is: (val) => val === "CONNECTIVITY",
    then: yup.string().required("¡Selecciona el Tipo!"),
  }),
  // Services
  videoUrlServices: yup.string().when("category", {
    is: (val) => val === "SERVICES",
    then: yup
      .string()
      .required("¡Completa la URL del Vídeo!")
      .min(5, "¡URL relativa no válida!"),
  }),
  specsOthers3Services: yup.string().when("category", {
    is: (val) => val === "SERVICES",
    then: yup.string().required("¡Completa los Otros (3)!"),
  }),
  specsOthers2Services: yup.string().when("category", {
    is: (val) => val === "SERVICES",
    then: yup.string().required("¡Completa los Otros (2)!"),
  }),
  specsOthers1Services: yup.string().when("category", {
    is: (val) => val === "SERVICES",
    then: yup.string().required("¡Completa los Otros (1)!"),
  }),
  specsDescriptionServices: yup.string().when("category", {
    is: (val) => val === "SERVICES",
    then: yup.string().required("¡Completa la Descripción!"),
  }),
  // Accessories
  moreInfoUrlAccessories: yup.string().when("category", {
    is: (val) => val === "ACCESSORIES",
    then: yup
      .string()
      .required("¡Completa la URL de Info!")
      .url("¡URL no válida!"),
  }),
  imageUrlAccessories: yup.string().when("category", {
    is: (val) => val === "ACCESSORIES",
    then: yup
      .string()
      .required("¡Completa la URL de la Imagen!")
      .min(5, "¡URL relativa no válida!"),
  }),
  specsOthers3Accessories: yup.string().when("category", {
    is: (val) => val === "ACCESSORIES",
    then: yup.string().required("¡Completa los Otros (3)!"),
  }),
  specsOthers2Accessories: yup.string().when("category", {
    is: (val) => val === "ACCESSORIES",
    then: yup.string().required("¡Completa los Otros (2)!"),
  }),
  specsOthers1Accessories: yup.string().when("category", {
    is: (val) => val === "ACCESSORIES",
    then: yup.string().required("¡Completa los Otros (1)!"),
  }),
  valueAccessories: yup.string().when("category", {
    is: (val) => val === "ACCESSORIES",
    then: yup.string().required("¡Selecciona el Valor!"),
  }),
  // Laptops
  moreInfoUrlLaptops: yup.string().when("category", {
    is: (val) => val === "LAPTOPS",
    then: yup
      .string()
      .required("¡Completa la URL de Info!")
      .url("¡URL no válida!"),
  }),
  imageUrlLaptops: yup.string().when("category", {
    is: (val) => val === "LAPTOPS",
    then: yup
      .string()
      .required("¡Completa la URL de la Imagen!")
      .min(5, "¡URL relativa no válida!"),
  }),
  specsRamStorageLaptops: yup.number().when("category", {
    is: (val) => val === "LAPTOPS",
    then: yup.number().required("¡Completa la RAM!"),
  }),
  specsInternalStorageLaptops: yup.number().when("category", {
    is: (val) => val === "LAPTOPS",
    then: yup.number().required("¡Completa el Almacenamiento!"),
  }),
  specsCpuLaptops: yup.string().when("category", {
    is: (val) => val === "LAPTOPS",
    then: yup.string().required("¡Completa el Procesador!"),
  }),
  specsDisplayLaptops: yup.string().when("category", {
    is: (val) => val === "LAPTOPS",
    then: yup.string().required("¡Completa la Pantalla!"),
  }),
  valueLaptops: yup.string().when("category", {
    is: (val) => val === "LAPTOPS",
    then: yup.string().required("¡Selecciona el Valor!"),
  }),
  // Tablets
  moreInfoUrlTablets: yup.string().when("category", {
    is: (val) => val === "TABLETS",
    then: yup
      .string()
      .required("¡Completa la URL de Info!")
      .url("¡URL no válida!"),
  }),
  imageUrlTablets: yup.string().when("category", {
    is: (val) => val === "TABLETS",
    then: yup
      .string()
      .required("¡Completa la URL de la Imagen!")
      .min(5, "¡URL relativa no válida!"),
  }),
  specsOthers2Tablets: yup.string().when("category", {
    is: (val) => val === "TABLETS",
    then: yup.string().required("¡Completa los Otros (2)!"),
  }),
  specsOthers1Tablets: yup.string().when("category", {
    is: (val) => val === "TABLETS",
    then: yup.string().required("¡Completa los Otros (1)!"),
  }),
  specsRamStorageTablets: yup.number().when("category", {
    is: (val) => val === "TABLETS",
    then: yup.number().required("¡Completa la RAM!"),
  }),
  specsInternalStorageTablets: yup.number().when("category", {
    is: (val) => val === "TABLETS",
    then: yup.number().required("¡Completa el Almacenamiento!"),
  }),
  specsDisplayTablets: yup.string().when("category", {
    is: (val) => val === "TABLETS",
    then: yup.string().required("¡Completa la Pantalla!"),
  }),
  valueTablets: yup.string().when("category", {
    is: (val) => val === "TABLETS",
    then: yup.string().required("¡Selecciona el Valor!"),
  }),
  // Rates
  specsInternetRates: yup.string().when("category", {
    is: (val) => val === "RATES",
    then: yup.string().required("¡Completa el Internet!"),
  }),
  specsCallsRates: yup.string().when("category", {
    is: (val) => val === "RATES",
    then: yup.string().required("¡Completa las Llamadas!"),
  }),
  valueRates: yup.string().when("category", {
    is: (val) => val === "RATES",
    then: yup.string().required("¡Selecciona el Valor!"),
  }),
  // Phones
  moreInfoUrlPhones: yup.string().when("category", {
    is: (val) => val === "PHONES",
    then: yup
      .string()
      .required("¡Completa la URL de Info!")
      .url("¡URL no válida!"),
  }),
  imageUrlPhones: yup.string().when("category", {
    is: (val) => val === "PHONES",
    then: yup
      .string()
      .required("¡Completa la URL de la Imagen!")
      .min(5, "¡URL relativa no válida!"),
  }),
  specsCameraPhones: yup.string().when("category", {
    is: (val) => val === "PHONES",
    then: yup.string().required("¡Completa la Cámara!"),
  }),
  specsRamStoragePhones: yup.number().when("category", {
    is: (val) => val === "PHONES",
    then: yup.number().required("¡Completa la RAM!"),
  }),
  specsInternalStoragePhones: yup.number().when("category", {
    is: (val) => val === "PHONES",
    then: yup.number().required("¡Completa el Almacenamiento!"),
  }),
  specsDisplayPhones: yup.string().when("category", {
    is: (val) => val === "PHONES",
    then: yup.string().required("¡Completa la Pantalla!"),
  }),
  valuePhones: yup.string().when("category", {
    is: (val) => val === "PHONES",
    then: yup.string().required("¡Selecciona el Valor!"),
  }),
  // Required values
  price: yup
    .string()
    .required("¡Completa el Precio!")
    .max(7, "¡Precio demasiado alto!"),
  title: yup
    .string()
    .required("¡Completa el Título!")
    .min(4, "¡Título demasiado corto!")
    .max(40, "¡Título demasiado largo!"),
  category: yup.string().required("¡Selecciona una categoría!"),
});
