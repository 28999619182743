import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
// Context
import { FirebaseContext } from "../Firebase";
import { AuthContext } from "../Auth";
import { ThemeModeContext } from "../ThemeMode";
import { StorageContext } from "../Storage";
import { DiaryDateContext } from "../Diary/DiaryDateContext";
import { BudgetsDateContext } from "../Budgets/BudgetsDateContext";
// Components
import Router from "../Router";
// Helpers
import getLocalForage from "../../helpers/getLocalForage";
import setLocalForage from "../../helpers/setLocalForage";
// Themes
import themeGlobal from "./themeGlobal";
import themeLight from "./themeLight";
import themeDark from "./themeDark";
// Moment
import moment from "moment";
import "moment/locale/es";

function App() {
  // Context
  const firebase = useContext(FirebaseContext);

  // * User Auth Handler
  const [user, setUser] = useState(null);

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userResponse) => {
      if (userResponse) {
        setUser(userResponse);
      } else {
        setUser(false);
      }
    });
  }, [firebase]);

  // * Handle Theme mode - Set it as light until the rest of the things are loaded
  const [themeMode, setThemeMode] = useState("light");

  // Load theme based in user preference
  const themeObj = useMemo(() => {
    if (themeMode === "light") {
      return {
        typography: { ...themeGlobal.typography, ...themeLight.typography },
        palette: { ...themeGlobal.palette, ...themeLight.palette },
        table: { ...themeGlobal.table, ...themeLight.table },
        overrides: { ...themeGlobal.overrides, ...themeLight.overrides },
      };
    } else {
      return {
        typography: { ...themeGlobal.typography, ...themeDark.typography },
        palette: { ...themeGlobal.palette, ...themeDark.palette },
        table: { ...themeGlobal.table, ...themeDark.table },
        overrides: { ...themeGlobal.overrides, ...themeDark.overrides },
      };
    }
  }, [themeMode]);

  // Pass the theme to the Context
  const theme = useMemo(() => createMuiTheme(themeObj), [themeObj]);

  // Set mode from localforage
  useEffect(() => {
    if (user !== null && user !== false) {
      (async () => {
        const currentThemeMode = await getLocalForage("themeMode");

        if (currentThemeMode === null) {
          await setLocalForage("themeMode", "light");
        } else {
          setThemeMode(currentThemeMode);
        }
      })();
    }
  }, [user]);

  // Pass a fn to change the theme mode from a Component
  async function changeThemeMode() {
    if (themeMode === "light") {
      await setLocalForage("themeMode", "dark");
      setThemeMode("dark");
    } else if (themeMode === "dark") {
      await setLocalForage("themeMode", "light");
      setThemeMode("light");
    }
  }

  /**
   * * Diary Handlers
   *
   * It will check the day or day-range selected by the user and only will retrieve the docs corresponding to that day
   */
  const [diaryDate, setDiaryDate] = useState(moment());
  const [forceUpdate, setForceUpdate] = useState(false);

  const minDate = useRef(moment().subtract(14, "days"));
  const maxDate = useRef(moment().add(14, "days"));

  function updateDiaryDate(newDate) {
    setDiaryDate(newDate);
    // This fn is necessary because moment() object doesn't changes never, and we need to do a re-render every time the Date changes
    setForceUpdate(!forceUpdate);
  }

  // * Effect when diaryDate is changed
  useEffect(() => {
    // Check if diaryDate isn't in the range that the App listens
    if (
      user !== null &&
      user !== false &&
      !diaryDate.isBetween(minDate.current, maxDate.current)
    ) {
      // Clone Moment object to don't alter the original object
      const minDateClone = moment(diaryDate);
      minDateClone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      const maxDateClone = moment(diaryDate);
      maxDateClone
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .add(1, "days");

      firebase
        .getVisits({
          userEmail: user.email,
          minDate: minDateClone.toDate(),
          maxDate: maxDateClone.toDate(),
        })
        .onSnapshot(async (response) => {
          // Response is always a querySnapshot / multiple docs because getVisits query the 'where date'
          let visits = [];

          response.forEach((doc) => {
            const data = { ...doc.data() };
            data.id = doc.id;
            visits.push(data);
          });

          // Format Visits date
          visits.forEach(
            (visit) => (visit.date = firebase.formatDate(visit.date))
          );

          // Save data to local storage
          await setLocalForage("visits", JSON.stringify(visits));
        });
    }
    // forceUpdate is needed because diaryDate is always the same, and I can't skip diaryDate because React detects that should be here
  }, [user, diaryDate, firebase, forceUpdate]);

  /**
   * * Budgets Handlers
   *
   * It will check the day or day-range selected by the user and only will retrieve the docs corresponding to that day
   */
  const [budgetsDate, setBudgetsDate] = useState(moment());
  // minDate, maxDate already declared in _Diary handler_

  function updateBudgetsDate(newDate) {
    setBudgetsDate(newDate);
    // This fn is necessary because moment() object doesn't changes never, and we need to do a re-render every time the Date changes
    setForceUpdate(!forceUpdate);
  }

  // * Effect when budgetsDate is changed
  useEffect(() => {
    // Check if budgetsDate isn't in the range that the App listens
    if (
      user !== null &&
      user !== false &&
      !budgetsDate.isBetween(minDate.current, maxDate.current)
    ) {
      // Clone Moment object to don't alter the original object
      const minDateClone = moment(budgetsDate);
      minDateClone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      const maxDateClone = moment(budgetsDate);
      maxDateClone
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .add(1, "days");

      firebase
        .getBudgets({
          userEmail: user.email,
          minDate: minDateClone.toDate(),
          maxDate: maxDateClone.toDate(),
        })
        .onSnapshot(async (response) => {
          // Response is always a querySnapshot / multiple docs because getVisits query the 'where date'
          let budgets = [];

          response.forEach((doc) => {
            const data = { ...doc.data() };
            data.id = doc.id;
            budgets.push(data);
          });

          // Format Visits date
          budgets.forEach(
            (budget) => (budget.date = firebase.formatDate(budget.date))
          );

          // Save data to local storage
          await setLocalForage("budgets", JSON.stringify(budgets));
        });
    }
    // forceUpdate is needed because budgetsDate is always the same, and I can't skip budgetsDate because React detects that should be here
  }, [budgetsDate, firebase, user, forceUpdate]);

  /**
   * * Storage Handlers
   *
   * The storage state will contain each needed collection: clients, customClients, visits, and products
   */
  const [storage, setStorage] = useState(null);

  const handleSetStorage = useCallback(async () => {
    // First get the data
    const clients = await getLocalForage("clients");
    const customClients = await getLocalForage("customClients");
    const visits = await getLocalForage("visits");

    // Exit because Clients and Visits are essential
    if (clients === null || visits === null) {
      return;
    }

    // Sometimes customClients can be empty because the commercial doesn't have customClients, so update state anyway
    if (customClients === null) {
      // Update state for all Screen components _without_ the customClients
      setStorage((prevStorage) => {
        return { ...prevStorage, clients, visits };
      });
    } else {
      // Update state for all Screen components _with_ the customClients
      setStorage((prevStorage) => {
        return { ...prevStorage, clients, customClients, visits };
      });
    }
  }, []);

  // * Storage: get Clients & Visits & CustomClients at real time (listens for changes)
  useEffect(() => {
    if (user !== null && user !== false) {
      // * Get Clients
      firebase.getClients(user.email).onSnapshot(async (response) => {
        // Evaluate if response is single doc (from user) or multiple docs / querySnapshot (from manager)
        let clients = [];

        if (response?.query) {
          response.forEach((doc) => {
            const docClients = doc.data().clients;
            if (docClients.length > 0) {
              clients = clients.concat(docClients);
            }
          });
        } else {
          const docClients = response.data().clients;
          if (docClients.length > 0) {
            clients = clients.concat(docClients);
          }
        }

        // Save Clients into local storage
        await setLocalForage("clients", JSON.stringify(clients));

        handleSetStorage();
      });

      // Only listen for Visits & CustomClients when Clients are in storage, because Clients are essential in the handleSetStorage fn
      // * Get Visits
      firebase
        .getVisits({
          userEmail: user.email,
          minDate: minDate.current.toDate(),
          maxDate: maxDate.current.toDate(),
        })
        .onSnapshot(async (response) => {
          // Response is always a querySnapshot / multiple docs because getVisits query the 'where date'
          let visits = [];

          response.forEach((doc) => {
            const data = { ...doc.data() };
            data.id = doc.id;
            visits.push(data);
          });

          // Format Visits date
          visits.forEach(
            (visit) => (visit.date = firebase.formatDate(visit.date))
          );

          // Save data to local storage
          await setLocalForage("visits", JSON.stringify(visits));

          // Update state for all Screen components
          handleSetStorage();
        });

      // * Get CustomClients
      firebase.getCustomClients(user.email).onSnapshot(async (response) => {
        // Evaluate if response is single doc (from user) or multiple docs / querySnapshot (from manager)
        let customClients = [];

        if (response?.query) {
          response.forEach((doc) => {
            const docClients = doc.data()?.clients;
            if (docClients?.length > 0) {
              customClients = customClients.concat(docClients);
            }
          });
        } else {
          const docClients = response.data()?.clients;
          if (docClients?.length > 0) {
            customClients = customClients.concat(docClients);
          }
        }

        // Save data to local storage
        await setLocalForage("customClients", JSON.stringify(customClients));

        // Update state for all Screen components
        handleSetStorage();
      });
    }
  }, [user, diaryDate, firebase, forceUpdate, handleSetStorage]);

  // * Listen for Products
  useEffect(() => {
    if (user !== null && user !== false) {
      const unsubscribe = firebase
        .getProducts()
        .onSnapshot(async (querySnapshot) => {
          var products = [];

          querySnapshot.forEach(function (doc) {
            products.push({ ...doc.data(), id: doc.id });
          });

          // Save data to storage
          setStorage((prevStorage) => {
            return { ...prevStorage, products };
          });
        });

      return () => unsubscribe();
    }
  }, [user, firebase]);

  let loading = true;

  if (storage !== null) {
    loading = false;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthContext.Provider value={user}>
        <DiaryDateContext.Provider value={{ diaryDate, updateDiaryDate }}>
          <BudgetsDateContext.Provider
            value={{ budgetsDate, updateBudgetsDate }}
          >
            <StorageContext.Provider value={storage}>
              <ThemeModeContext.Provider value={{ changeThemeMode, themeMode }}>
                <Router user={user} loading={loading} />
              </ThemeModeContext.Provider>
            </StorageContext.Provider>
          </BudgetsDateContext.Provider>
        </DiaryDateContext.Provider>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
